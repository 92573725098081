import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Container, Row, Col } from 'reactstrap';
import { Form, Field, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';

import axios from 'axios';
import { getSimplAdminUrl } from '../utils/env';

import * as actions from '../store/actions/wamp';

class FormikForm extends React.PureComponent {
  render() {
    // console.log('AddLeaderForm: props:', this.props);
    return (
      <Container>
        <Row>
          <Col>
            <Form layout="inline">
              <div className="form-group">
                <Field
                  type="text"
                  name="email"
                  placeholder="Email address"
                  className="form-control"
                />
                <ErrorMessage name="email" />
              </div>
              <Button
                className="btn btn-labeled btn-bay-blue float-right"
                type="submit"
              >
                <span className="btn-label">
                  <FontAwesomeIcon icon="play" />
                </span>{' '}
                Start Task
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <span>
              <strong>NOTE</strong> tasks may take a few minutes to complete.
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}

const AddLeaderValidation = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
});

const AddLeaderForm = withFormik({
  mapPropsToValues: props => {
    return {
      email: '',
    };
  },
  validationSchema: AddLeaderValidation,
  validateOnChange: true,
  validateOnBlur: true,
  handleSubmit: (
    values,
    { props: { loading, error, addLeader, simplAdminToken } }
  ) => {
    // console.log('values:', values);
    const email = values.email;
    // console.log('handleSubmit email:', email);
    addLeader(email, simplAdminToken);
  },
})(FormikForm);

// form container

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps:', ownProps);
  const { simplAdminToken } = state.auth;
  return {
    simplAdminToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addLeader: (email, simplAdminToken) => {
      const { run, gameSlug, afterSubmit } = ownProps;
      // console.log('addLeader: run:', run);
      dispatch(actions.addLeaderToRun(gameSlug, run.name, email))
        .then(result => {
          const { payload } = result;
          const { celery_task_id, error } = payload;
          console.log(
            'addLeaderToRun celery_task_id:',
            celery_task_id,
            ', error:',
            error
          );
          if (typeof error === 'undefined') {
            // add task to database
            axios
              .post(
                `${getSimplAdminUrl()}/api/tasks/`,
                {
                  game_slug: gameSlug,
                  task_name: 'add_leader_to_run',
                  celery_task_id: celery_task_id,
                  run_id: run.id,
                },
                { headers: { Authorization: simplAdminToken } }
              )
              .then(res => {
                // console.log(res);
                afterSubmit();
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(error => {
          console.log('addLeader error:', error);
        });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddLeaderForm)
);
