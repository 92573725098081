import React from 'react';

import { connect } from 'react-redux';

import Header from '../containers/Header';
import Footer from '../components/Footer';
import BreadcrumbBar from '../containers/BreadcrumbBar';
import GameTable from '../containers/GameTable';

import { getGamesData, initGamesData } from '../utils/gamesData';

// import {getModelServiceWs} from '../utils/env';

class GamesPage extends React.Component {
  componentDidMount() {
    const { simplToken } = this.props;
    const gamesData = getGamesData();
    // console.log('GamesPage.componentDidMount gamesData:', gamesData);
    if (Object.keys(gamesData).length === 0) {
      // console.log('need to load gamesData');
      initGamesData(simplToken);
    }
  }

  render() {
    // const wsUrl = getModelServiceWs('foo');
    // console.log('wsUrl:', wsUrl);
    return (
      <div>
        <Header />
        <BreadcrumbBar />
        <div className="admin-content">
          <div className="projects-wrapper">
            <div className="scroll-table-wrapper">
              <GameTable />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { simplToken } = state.auth;
  return {
    simplToken,
  };
};

export default connect(mapStateToProps, null)(GamesPage);
