import AutobahnReact from './autobahn';
import Progress from './components/Progress.react';

import { CONNECTION_STATUS } from './constants';

export function wampSetup(component, options) {
  console.log('pre Simpl 1.0 WampSetup:');

  // Callback called whenever the connection is lost
  AutobahnReact.Connection.onLost(() => {
    console.log('Connection lost :/!');
    component.props.setConnectionStatus(CONNECTION_STATUS.OFFLINE);
  });

  // Callback called whenever the connection is ready
  // eslint-disable-next-line no-unused-vars
  AutobahnReact.Connection.onReady(([session, details]) => {
    console.log('Connection established!');
    console.log('root_topic:', options.root_topic);
    session.prefix('model', options.root_topic);
    Object.keys(options.prefixes).forEach(key => {
      const value = options.prefixes[key];
      console.log('added prefix: ', key, value);
      session.prefix(key, value);
      component.props.setConnectionStatus(CONNECTION_STATUS.CONNECTED);
    });
  });
  console.log(
    '-> AutobahnReact.Connection.initialize: url:',
    options.url,
    ', realm:',
    options.realm
  );

  AutobahnReact.Connection.initialize(options.url, options.realm);
  if (options.authid) {
    AutobahnReact.Auth.logIn({
      username: `${options.authid}`,
      password: options.password,
    })
      .then(() => {
        console.log('authenticated');
        if (component.props.onReady) {
          component.props.onReady();
        }
      })
      .catch(err => {
        console.log(err);
        console.log('not authenticated');
      });
  }
}

export function newWampSetup(component, options) {
  console.log('Simpl 1.0 WampSetup:');

  // Callback called whenever the connection is lost
  AutobahnReact.Connection.onLost(() => {
    console.log('Connection lost :/!');
    component.props.setConnectionStatus(CONNECTION_STATUS.OFFLINE);
  });
  // Callback called whenever the connection is ready
  // eslint-disable-next-line no-unused-vars
  AutobahnReact.Connection.onReady(([session, details]) => {
    console.log('Connection established!');
    session.prefix('model', options.root_topic);
    Object.keys(options.prefixes).forEach(key => {
      const value = options.prefixes[key];
      console.log('added prefix: ', key, value);
      session.prefix(key, value);
      component.props.setConnectionStatus(CONNECTION_STATUS.CONNECTED);
    });
  });

  AutobahnReact.Connection.makeConnection({
    url: options.url,
    realm: options.realm,
    authmethods: ['ticket'],
    authid: options.authid,
    onchallenge: () => {
      return options.password;
    },
  });

  AutobahnReact.Connection.connect()
    .then(() => {
      console.log(`Authentication as authid=${options.authid} successful!`);
      if (component.props.onReady) {
        component.props.onReady();
      }
    })
    .catch(err => {
      console.log(err);
      console.log(`Unable to authenticate as authid=${options.authid}`);
    });
}

export function wampOptionsWithDefaults(options) {
  const defaults = {
    url: 'ws://localhost:8080/ws',
    realm: 'realm1',
    authmethods: ['ticket'],
    prefixes: {},
    progressComponent: Progress,
  };
  return Object.assign({}, defaults, options);
}
