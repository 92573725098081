import React from 'react';
import { connect } from 'react-redux';

import logo from '../images/wharton-logo-footer.png';

import LoginForm from '../containers/LoginForm';
import * as actions from '../store/actions/auth';

class LoginPage extends React.Component {
  render() {
    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col-sm-8 col-md-6 col-lg-6">
              <div className="panel panel-default login-box">
                <div className="panel-body">
                  <div className="login-header">Simpl Admin</div>
                  <LoginForm {...this.props} />
                </div>
                <br />
                <div className="panel-footer">
                  <div className="login-footer">
                    <div className="login-footer-logo">
                      <img src={logo} alt={'Simpl Admin'} />
                    </div>

                    <div className="login-footer-text text-right">
                      Powered&nbsp;by
                      <br />
                      <strong>Learning&nbsp;Lab</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.authLogin(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
