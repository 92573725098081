// Any simulation whose slug IS NOT included does not run simpl-admin tasks.
export const taskRunners = new Set(['pop', 'roe']);

// By default, all members of taskRunners are assumed to use the user id for authentication.
// Any simulation whose slug IS included in emailAuthId uses the user email/password for authentication.
export const emailAuthId = new Set();

// By default, all members of taskRunners are assumed to run simpl-admin Canvas tasks.
// Any simulation whose slug IS included in hideCanvasTasks does NOT run simpl-admin Canvas tasks.
export const hideCanvasTasks = new Set();

// Used when REACT_APP_ENV is not 'local', will
// replace ':slug:' with the current game's slug and
// replace ':env:' with the REACT_APP_ENV value
export const TEMPLATE_MODEL_SERVICE_WS =
  'wss://:slug:-model.:env:.wharton.revsys.com/ws';

// Used when REACT_APP_ENV is 'local',
// for wamp with a local game's model service
export const MODEL_SERVICE_WS = 'ws://localhost:8080/ws';

export const ROOT_TOPIC_ROOT = 'edu.upenn.sims.'; // plus game slug == game's ROOT_TOPIC

export const AUTO_REFRESH_RATE_MS = 10000;
