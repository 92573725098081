import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import { getSimplUrl } from '../../utils/env';

class DeleteRunBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => {
    // const { isModalOpen } = this.state
    // console.log('DeleteRunBtn: openModal: isModalOpen=', isModalOpen)
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state
    // console.log('DeleteRunBtn: closeModal: isModalOpen=', isModalOpen)
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  confirmDelete = e => {
    // const { isModalOpen } = this.state
    // console.log('DeleteRunBtn: confirmDelete: isModalOpen=', isModalOpen)
    e.preventDefault();
    this.openModal();
  };

  deleteRun = () => {
    const { handleDelete, simplToken } = this.props;
    // console.log('DeleteRunBtn: deleteRun')
    this.closeModal();

    handleDelete(simplToken);
  };

  renderModal() {
    const { run } = this.props;
    const { isModalOpen } = this.state;

    // console.log('DeleteRunBtn: run:', run);

    return (
      <Modal size="sm" isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Delete Run</span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col className="text-center">
                <p>Delete this run?</p>

                <p>
                  <b>{run.name}</b>
                </p>

                <Button
                  className="btn btn-labeled "
                  color="success"
                  onClick={this.deleteRun}
                >
                  <span className="btn-label">
                    <FontAwesomeIcon icon="check" />
                  </span>
                  Delete
                </Button>

                {/*<Button color="danger" size="xs" onClick={this.closeModal}>*/}
                {/*  <span className="mr-2">*/}
                {/*    <FontAwesomeIcon icon="times" />*/}
                {/*  </span>*/}
                {/*  Cancel*/}
                {/*</Button>*/}
                <span>&nbsp;</span>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { run } = this.props;
    // console.log('DeleteRunBtn: render run=', run);

    if (typeof run === 'undefined') {
      return <span>N/A</span>;
    }

    return (
      <div>
        <Button
          color="heritage-red"
          size="xs"
          title="Delete"
          onClick={this.confirmDelete}
        >
          <FontAwesomeIcon icon="trash-alt" />
        </Button>

        {this.renderModal()}
      </div>
    );
  }
}

DeleteRunBtn.propTypes = {
  run: PropTypes.object.isRequired,

  // dispatch actions
  handleDelete: PropTypes.func.isRequired,
};

// DeleteRunBtn container

const mapStateToProps = (state, props) => {
  const { run } = props;
  const { simplToken } = state.auth;

  return {
    run,
    simplToken,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleDelete(simplToken) {
      const { run, afterDelete } = ownProps;
      // console.log('handleDelete dispatch: run:', run);

      const simplUrl = getSimplUrl();
      const url = `${simplUrl}/apis/runs/${run.id}/`;
      // console.log('url:', url);
      axios
        .delete(url, {
          headers: { Authorization: simplToken },
        })
        .then(res => {
          // console.log(res);
          afterDelete();
        });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRunBtn);
