import {
  ROOT_TOPIC_ROOT,
  TEMPLATE_MODEL_SERVICE_WS,
  MODEL_SERVICE_WS,
} from '../constants';

export function getRootTopic(gameSlug) {
  const rootTopic = `${ROOT_TOPIC_ROOT}${gameSlug}`;
  // console.log('rootTopic:', rootTopic);
  return rootTopic;
}

export function getModelServiceWs(gameSlug) {
  let url = '';
  // console.log('process.env.REACT_APP_ENV:', process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === 'local') {
    url = MODEL_SERVICE_WS;
  } else {
    const env = process.env.REACT_APP_ENV;
    url = TEMPLATE_MODEL_SERVICE_WS.replace(':slug:', gameSlug);
    url = url.replace(':env:', env);
  }
  // console.log('MODEL_SERVICE_WS:', url);
  return url;
}

export function getSimplAdminUrl() {
  // console.log(
  //   'REACT_APP_SIMPL_ADMIN_URL:',
  //   process.env.REACT_APP_SIMPL_ADMIN_URL
  // );
  return process.env.REACT_APP_SIMPL_ADMIN_URL;
}

export function getSimplUrl() {
  // console.log(
  //   'REACT_APP_SIMPL_URL:',
  //   process.env.REACT_APP_SIMPL_URL
  // );
  return process.env.REACT_APP_SIMPL_URL;
}
