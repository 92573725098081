import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class GameRow extends React.Component {
  render() {
    const { id, name, slug } = this.props.game;

    const linkDestination = `/games/${slug}/runs/`;

    return (
      <tr>
        <td>
          <Link to={linkDestination} id={id}>
            {name}
          </Link>
        </td>
        <td>{slug}</td>
      </tr>
    );
  }
}

GameRow.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameRow;
