import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import { getSimplAdminUrl } from '../../utils/env';

import { deleteRunUser } from '../../store/actions/wamp';

class DeleteRunUserBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => {
    // const { isModalOpen } = this.state
    // console.log('DeleteRunUserBtn: openModal: isModalOpen=', isModalOpen)
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state
    // console.log('DeleteRunUserBtn: closeModal: isModalOpen=', isModalOpen)
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  confirmDelete = e => {
    // const { isModalOpen } = this.state
    // console.log('DeleteRunUserBtn: confirmDelete: isModalOpen=', isModalOpen)
    e.preventDefault();
    this.openModal();
  };

  deleteRunUser = () => {
    const { handleDelete, afterDelete } = this.props;
    // console.log('DeleteRunUserBtn: handleDelete')
    this.closeModal();

    handleDelete(this.props);
    afterDelete();
  };

  renderModal() {
    const { runuser } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Modal size="sm" isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Remove User</span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col className="text-center">
                <p>Remove this User from run?</p>
                <p>
                  <b>
                    {runuser.first_name} {runuser.last_name}
                  </b>
                </p>

                <Button
                  className="btn btn-labeled btn-bay-blue"
                  onClick={this.deleteRunUser}
                >
                  <span className="btn-label">
                    <FontAwesomeIcon icon="play" />
                  </span>
                  Start Task
                </Button>
                <span>&nbsp;</span>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { runuser } = this.props;
    // console.log('DeleteRunUserBtn: render runuser=', runuser)
    // console.log('DeleteRunUserBtn.render: gameSlug:', gameSlug);

    if (typeof runuser === 'undefined') {
      return <span>N/A</span>;
    }

    return (
      <div>
        <Button
          color="heritage-red"
          size="xs"
          title="Remove"
          onClick={this.confirmDelete}
        >
          <FontAwesomeIcon icon="trash-alt" />
        </Button>

        {this.renderModal()}
      </div>
    );
  }
}

DeleteRunUserBtn.propTypes = {
  runuser: PropTypes.object.isRequired,
  simplAdminToken: PropTypes.string,

  // dispatch actions
  handleDelete: PropTypes.func.isRequired,
};

DeleteRunUserBtn.defaultProps = {
  runuser: null,
};

// DeleteRunUserBtn container

const mapStateToProps = state => {
  // console.log('DeleteRunUserBtn.mapStateToProps: ownProps:', ownProps);

  const { simplAdminToken } = state.auth;
  // console.log('simplAdminToken', simplAdminToken);
  return {
    simplAdminToken,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleDelete(props) {
      console.log('DeleteRunUserBtn handleDelete');
      console.log('props:', props);
      console.log('ownProps:', ownProps);

      const { gameSlug, runName, afterDelete } = ownProps;
      const { simplAdminToken, runuser } = props;
      const { email } = runuser;

      console.log('runId:', runName, ', email:', email);

      dispatch(deleteRunUser(gameSlug, runName, email))
        .then(result => {
          const { payload } = result;
          const { celery_task_id, error } = payload;
          console.log(
            'deleteRunUser celery_task_id:',
            celery_task_id,
            ', error:',
            error
          );
          if (typeof error === 'undefined') {
            // add task to database
            axios
              .post(
                `${getSimplAdminUrl()}/api/tasks/`,
                {
                  game_slug: gameSlug,
                  task_name: 'delete_run_user',
                  celery_task_id: celery_task_id,
                  run_id: runuser.run,
                },
                { headers: { Authorization: simplAdminToken } }
              )
              .then(res => {
                console.log(res);
                afterDelete();
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(error => {
          console.log('deleteRunUser error:', error);
        });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRunUserBtn);
