import React from 'react';

import logo from '../images/wharton-logo-footer.png';

const Footer = props => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="footer-logo">
          <img src={logo} alt="Wharton" />
        </div>
        <div className="footer-right">
          &copy;{year} The Wharton School, The University of Pennsylvania
        </div>
        <div className="footer-text">
          Powered&nbsp;by
          <br />
          <strong>Learning&nbsp;Lab</strong>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
