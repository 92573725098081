import React from 'react';

import { Row, Col } from 'reactstrap';

import { getNYDisplayDateTime } from '../utils/datetime';

import axios from 'axios';
import { getSimplAdminUrl, getSimplUrl } from '../utils/env';

import Header from '../containers/Header';
import Footer from '../components/Footer';
import BreadcrumbBar from '../containers/BreadcrumbBar';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Displays a game task

class TaskPage extends React.Component {
  state = {
    task: null,
    run: null,
  };

  componentDidMount() {
    const { simplToken, simplAdminToken } = this.props;

    const { runId, taskId } = this.props.match.params;
    console.log('runId:', runId);
    console.log('taskId:', taskId);

    const url = `${getSimplAdminUrl()}/api/tasks/${taskId}/`;
    // console.log('url:', url);
    axios
      .get(url, {
        headers: { Authorization: simplAdminToken },
      })
      .then(res => {
        this.setState({
          task: res.data,
        });

        if (typeof runId !== 'undefined') {
          const url = `${getSimplUrl()}/apis/runs/${runId}/`;
          // console.log('url:', url);
          axios
            .get(url, {
              headers: { Authorization: simplToken },
            })
            .then(res => {
              this.setState({
                run: res.data,
              });
              // console.log('run: res.data:', res.data);
            });
        }
      });
  }

  render() {
    const { task, run } = this.state;
    // console.log('task:', task);

    if (task === null) {
      return (
        <div>
          <Header />
          <Footer />
        </div>
      );
    }

    const { created, task_name, task_result } = task;

    // const name = `${game_slug}.${task_name}`;
    const created_date = getNYDisplayDateTime(created);

    const status = task_result.status;
    const args = task_result.task_args;
    const result = task_result.result;

    const resultLines = result.split('\\n');
    // console.log('resultLines:', resultLines);

    const parsedResult = JSON.parse(result);
    // console.log('parsedResult:', parsedResult);

    const runName = run !== null ? run.name : '';

    return (
      <div>
        <Header />
        <BreadcrumbBar taskName={task.task_name} runName={runName} />
        <div className="admin-content">
          <Row>
            <Col md="12">
              <div className="projects-wrapper">
                <Row>
                  <Col>Name: {task_name}</Col>
                </Row>
                <Row>
                  <Col>Created: {created_date}</Col>
                </Row>
                <Row>
                  <Col>Args: {args}</Col>
                </Row>
                <Row>
                  <Col>Status: {status}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="projects-wrapper">
                <Row>
                  <Col>Result:</Col>
                </Row>
                <Row>
                  <Col>
                    <div className="scroll-table-wrapper">
                      <textarea
                        readOnly
                        cols="120"
                        rows={resultLines.length}
                        value={parsedResult}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

TaskPage.propTypes = {
  simplToken: PropTypes.string.isRequired,
  simplAdminToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { simplToken, simplAdminToken } = state.auth;
  return {
    simplToken,
    simplAdminToken,
  };
};

export default withRouter(connect(mapStateToProps, null)(TaskPage));
