import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import * as actions from '../../store/actions/wamp';
import axios from 'axios';
import { getSimplAdminUrl } from '../../utils/env';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import CSVReader from 'react-csv-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, ''),
};

class AddPlayersBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isModalOpen: false,
    };
  }

  openModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('AddPlayersBtn: openModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('AddPlayersBtn: closeModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  handleUpload = data => {
    console.log('handleUpload: data:', data);
    this.setState({ data: data });
  };

  handleStartTask = () => {
    const { data } = this.state;
    const { simplAdminToken, addPlayers } = this.props;
    addPlayers(data, simplAdminToken);
    this.setState({ data: [] });
    this.closeModal();
  };

  renderModal() {
    const { isModalOpen, data } = this.state;

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Add Players to Run</span>
        </ModalHeader>
        <ModalBody className="text-center">
          <Container>
            <Row>
              <Col>
                <CSVReader
                  label="Select CSV file containing player info "
                  onFileLoaded={this.handleUpload}
                  parserOptions={papaparseOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="btn btn-labeled btn-bay-blue float-right"
                  disabled={data.length === 0}
                  onClick={this.handleStartTask}
                >
                  <span className="btn-label">
                    <FontAwesomeIcon icon="play" />
                  </span>{' '}
                  Start Task
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <span>
                  <strong>NOTE</strong> tasks may take a few minutes to
                  complete.
                </span>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <Button className="btn btn-bay-blue" onClick={this.openModal}>
          Add Players
        </Button>
        {this.renderModal()}
      </div>
    );
  }
}

AddPlayersBtn.propTypes = {
  run: PropTypes.object.isRequired,
  gameSlug: PropTypes.string.isRequired,
  addPlayers: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps:', ownProps);
  const { simplAdminToken } = state.auth;
  return {
    simplAdminToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPlayers: (playerJson, simplAdminToken) => {
      const { run, gameSlug, afterChange } = ownProps;
      console.log('addPlayers:');
      dispatch(actions.addPlayersToRun(gameSlug, run.name, playerJson))
        .then(result => {
          const { payload } = result;
          const { celery_task_id, error } = payload;
          console.log(
            'addPlayersToRun celery_task_id:',
            celery_task_id,
            ', error:',
            error
          );
          if (typeof error === 'undefined') {
            // add task to database
            axios
              .post(
                `${getSimplAdminUrl()}/api/tasks/`,
                {
                  game_slug: gameSlug,
                  task_name: 'add_players_to_run',
                  celery_task_id: celery_task_id,
                  run_id: run.id,
                },
                { headers: { Authorization: simplAdminToken } }
              )
              .then(res => {
                // console.log(res);
                afterChange();
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(error => {
          console.log('addPlayers error:', error);
        });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPlayersBtn)
);
