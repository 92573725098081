import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { Row, Col, Container } from 'reactstrap';

import axios from 'axios';
import {
  getSimplUrl,
  getSimplAdminUrl,
  getModelServiceWs,
  getRootTopic,
} from '../../utils/env';

import {
  taskRunners,
  emailAuthId,
  AUTO_REFRESH_RATE_MS,
} from '../../constants';

import { getNextPhase, getPrevPhase } from '../../utils/phase';
import { getGameData } from '../../utils/gamesData';

import Header from '../../containers/Header';
import Footer from '../../components/Footer';
import BreadcrumbBar from '../../containers/BreadcrumbBar';

import EditNameBtn from './EditNameBtn';
import StatusToggle from './StatusToggle';
import RunUserTable from '../../containers/RunUserTable';
import PendingTasks from '../../components/PendingTasks';
import AddLeaderBtn from './AddLeaderBtn';
import AddPlayersBtn from './AddPlayersBtn';
import AdvancePhaseBtn from './AdvancePhaseBtn';
import RewindPhaseBtn from './RewindPhaseBtn';

import Progress from '../../simpl-wamp/components/Progress.react';
import {
  wampOptionsWithDefaults,
  wampSetup,
  newWampSetup,
} from '../../simpl-wamp/utils';

// Displays a game run

class RunPage extends React.Component {
  constructor(props) {
    super(props);

    this.timeoutId = 0;
    this.refreshPageState = this.refreshPageState.bind(this);
    this.afterChange = this.afterChange.bind(this);

    this.state = {
      run: null,
      runusers: [],
      worlds: [],
      tasks: [],
      pendingTasks: [],
    };
  }

  refreshPageState = () => {
    const { runId } = this.props.match.params;
    // console.log('runId:', runId);
    const { simplToken, simplAdminToken } = this.props;

    const simplUrl = getSimplUrl();

    const url = `${simplUrl}/apis/runs/${runId}/`;
    // console.log('url:', url);
    axios
      .get(url, {
        headers: { Authorization: simplToken },
      })
      .then(res => {
        this.setState({
          run: res.data,
        });
        // console.log('run: res.data:', res.data);

        const url = `${simplUrl}/apis/runusers/?run=${runId}`;
        // console.log('url:', url);
        axios
          .get(url, {
            headers: { Authorization: simplToken },
          })
          .then(res => {
            this.setState({
              runusers: res.data,
            });
            // console.log('runusers: res.data:', res.data);

            const url = `${simplUrl}/apis/worlds/?run=${runId}`;
            // console.log('url:', url);
            axios
              .get(url, {
                headers: { Authorization: simplToken },
              })
              .then(res => {
                this.setState({
                  worlds: res.data,
                });
                // console.log('worlds: res.data:', res.data);

                const simplAdminUrl = getSimplAdminUrl();
                const url = `${simplAdminUrl}/api/tasks/?run_id=${runId}`;
                axios
                  .get(url, {
                    headers: { Authorization: simplAdminToken },
                  })
                  .then(res => {
                    const tasks = res.data;
                    this.setState({
                      tasks: tasks,
                    });

                    if (tasks.length > 0) {
                      const url = `${simplAdminUrl}/api/tasks/pending_tasks/?run_id=${runId}`;
                      axios
                        .get(url, {
                          headers: { Authorization: simplAdminToken },
                        })
                        .then(res => {
                          this.setState({
                            pendingTasks: res.data,
                          });
                          // console.log('pendingTasks:', res.data);
                        });
                    }

                    this.timeoutId = setTimeout(
                      this.refreshPageState,
                      AUTO_REFRESH_RATE_MS
                    );
                    // console.log('timeoutId:', this.timeoutId);
                  });
              });
          });
      });
  };

  componentDidMount = () => {
    const { gameSlug } = this.props;
    // console.log('gameSlug:', gameSlug);

    this.refreshPageState();

    const authId = localStorage.getItem('authId');
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    const options = {
      authid: authId,
      password: 'nopassword',
      url: getModelServiceWs(gameSlug),
      realm: 'realm1',
      prefixes: {},
      progressComponent: Progress,
    };

    const newAuth = emailAuthId.has(gameSlug);
    if (newAuth) {
      options.authid = email;
      options.password = password;
    }
    console.log('options:', options);
    const optionsWithDefaults = wampOptionsWithDefaults(options);

    const rootTopic = getRootTopic(gameSlug);
    console.log('rootTopic:', rootTopic);

    if (newAuth) {
      newWampSetup(this, optionsWithDefaults);
    } else {
      wampSetup(this, optionsWithDefaults);
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  afterChange() {
    // console.log('RunPage.afterChange');
    clearTimeout(this.timeoutId);
    this.refreshPageState();
  }

  render() {
    const { run, runusers, worlds, tasks, pendingTasks } = this.state;
    // console.log('RunPage:  run:', run);
    // console.log('RunPage:  runusers:', runusers);
    // console.log('RunPage:  worlds:', worlds);
    // console.log('RunPage:  tasks:', tasks);
    // console.log('RunPage:  pendingTasks:', pendingTasks);

    const { gameSlug, gameData, simplToken } = this.props;

    if (run === null || typeof gameData === 'undefined' || gameData === null) {
      return (
        <div>
          <Header />
          <Footer />
        </div>
      );
    }

    const { id, name, phase } = run;
    // console.log('run: name:', name, ', phase:', phase);

    const currentPhase = gameData.phases[phase];
    const phaseName = currentPhase.name;

    // console.log('gameData.phases:', gameData.phases);
    // console.log('currentPhase:', currentPhase);

    const prevPhase = getPrevPhase(gameData.phases, currentPhase);
    // console.log('prevPhase:', prevPhase);

    const nextPhase = getNextPhase(gameData.phases, currentPhase);
    // console.log('nextPhase:', nextPhase);

    const runData = run.data;
    const canvasRun =
      typeof runData.canvas !== 'undefined' &&
      typeof runData.canvas.course_id !== 'undefined';
    const courseId = canvasRun ? runData.canvas.course_id : '';
    const sectionId = canvasRun ? runData.canvas.section_id : '';

    let addPlayersBtn = '';
    if (!canvasRun) {
      addPlayersBtn = (
        <Row>
          <Col>
            <AddPlayersBtn
              run={run}
              gameSlug={gameSlug}
              afterChange={this.afterChange}
            />
            <br />
          </Col>
        </Row>
      );
    }

    let editNameBtn = '';
    if (!canvasRun && typeof run !== 'undefined') {
      editNameBtn = (
        <EditNameBtn
          run={run}
          gameSlug={gameSlug}
          simplToken={simplToken}
          afterChange={this.afterChange}
        />
      );
    }

    let taskAccess = '';
    if (taskRunners.has(gameSlug)) {
      let advanceBtn = '';
      if (nextPhase !== null) {
        advanceBtn = (
          <Row>
            <Col>
              <AdvancePhaseBtn
                run={run}
                gameData={gameData}
                gameSlug={gameSlug}
                afterChange={this.afterChange}
              />
              <br />
            </Col>
          </Row>
        );
      }

      let rewindBtn = '';
      if (prevPhase !== null) {
        rewindBtn = (
          <Row>
            <Col>
              <RewindPhaseBtn
                run={run}
                gameData={gameData}
                gameSlug={gameSlug}
                afterChange={this.afterChange}
              />
              <br />
            </Col>
          </Row>
        );
      }

      taskAccess = (
        <div className="projects-wrapper">
          <Container>
            <Row>
              <Col>
                <PendingTasks
                  tasks={tasks}
                  pendingTasks={pendingTasks}
                  gameSlug={gameSlug}
                  runId={id}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AddLeaderBtn
                  run={run}
                  gameSlug={gameSlug}
                  afterChange={this.afterChange}
                />
                <br />
              </Col>
            </Row>
            {addPlayersBtn}
            {advanceBtn}
            {rewindBtn}
          </Container>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <BreadcrumbBar runName={name} />
        <div className="admin-content">
          <Row>
            <Col md="8">
              <div className="projects-wrapper">
                <Row className="tall-row">
                  <Col>
                    <span className="stronger">Name:</span> {name} &nbsp;{' '}
                    {editNameBtn}
                  </Col>
                </Row>
                <Row className="tall-row">
                  <Col>
                    <span className="stronger">Active:</span>{' '}
                    <StatusToggle run={run} afterChange={this.afterChange} />
                  </Col>
                </Row>
                <Row className="tall-row">
                  <Col>
                    <span className="stronger">Phase:</span> {phaseName}
                  </Col>
                </Row>
                <Row className="tall-row">
                  <Col>
                    <span className="stronger">Canvas Course Id:</span>{' '}
                    {courseId}
                  </Col>
                </Row>
                <Row className="tall-row">
                  <Col>
                    <span className="stronger">Canvas Section Id:</span>{' '}
                    {sectionId}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="4">{taskAccess}</Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="projects-wrapper">
                <div className="scroll-table-wrapper">
                  <RunUserTable
                    runusers={runusers}
                    worlds={worlds}
                    lti={canvasRun}
                    gameSlug={gameSlug}
                    runName={name}
                    afterChange={this.afterChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

RunPage.propTypes = {
  gameSlug: PropTypes.string.isRequired,
  gameData: PropTypes.object.isRequired,
  simplToken: PropTypes.string.isRequired,
  simplAdminToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { gameSlug } = props.match.params;
  const gameData = getGameData(gameSlug);
  // console.log('RunPage: gameSlug:', gameSlug, ', gameData:', gameData);
  const { simplToken, simplAdminToken } = state.auth;
  return {
    gameSlug,
    gameData,
    simplToken,
    simplAdminToken,
  };
};

export default connect(mapStateToProps, null)(RunPage);
