import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { taskRunners } from '../../constants';

import { getNYDisplayDateTime } from '../../utils/datetime';

import DeleteRunBtn from './DeleteRunBtn';

class RunRow extends React.Component {
  render() {
    const { run, gameData, gameSlug, afterDelete } = this.props;
    const { id, name, phase, active, created, data } = run;

    const linkDestination = `/games/${gameSlug}/runs/${id}`;
    // console.log('RunRow: linkDestination:', linkDestination);

    const status = active ? 'Active' : 'Inactive';
    const created_date = getNYDisplayDateTime(created);
    const phaseName = gameData.phases[phase].name;
    const courseId = data.canvas ? data.canvas.course_id : '';

    let deleteBtn = '';
    if (taskRunners.has(gameSlug)) {
      deleteBtn = <DeleteRunBtn run={run} afterDelete={afterDelete} />;
    }

    return (
      <tr>
        <td>
          <Link to={linkDestination} id={id}>
            {name}
          </Link>
        </td>
        <td>{courseId}</td>
        <td>{phaseName}</td>
        <td>{status}</td>
        <td>{created_date}</td>
        <td>{deleteBtn}</td>
      </tr>
    );
  }
}

RunRow.propTypes = {
  run: PropTypes.object.isRequired,
  gameData: PropTypes.object.isRequired,
  gameSlug: PropTypes.string.isRequired,
  afterDelete: PropTypes.func.isRequired,
};

export default RunRow;
