import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import BodyClassName from 'react-body-classname';
import { Link } from 'react-router-dom';
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as actions from '../store/actions/auth';
import logo from '../images/wharton-logo-blue-sm.png';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavbarOpen: false,
      isModalOpen: false,
    };
  }

  toggleNavbar = () => {
    this.setState(state => ({
      isNavbarOpen: !state.isNavbarOpen,
    }));
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  renderModal() {
    const { isModalOpen } = this.state;

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader>
          <img src={logo} alt="Simpl Admin" className="img-fluid rounded" />
        </ModalHeader>

        <ModalBody className="py-5">
          <div className="logout-text">Do you want to exit Simpl Admin?</div>

          <div className="text-center mt-4">
            <Button
              color="success"
              size="lg"
              onClick={this.props.logout}
              className="mr-3"
            >
              <span>
                <FontAwesomeIcon icon="check" />
              </span>
              &nbsp;Yes
            </Button>

            <Button color="heritage-red" size="lg" onClick={this.toggleModal}>
              <span>
                <FontAwesomeIcon icon="times" />
              </span>
              &nbsp;No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { email } = this.props;
    const { isNavbarOpen } = this.state;

    return (
      <BodyClassName className="has-header">
        <Navbar dark fixed="top" expand="md" className="px-4">
          <NavbarBrand tag={Link} to="/">
            Simpl Admin
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} />

          <Collapse isOpen={isNavbarOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="nav-item-user-info">
                <div className="nav-item-user-name">{email}</div>
              </NavItem>

              <UncontrolledDropdown
                nav
                inNavbar
                className="nav-item-dropdown-menu ml-2"
              >
                <DropdownToggle nav>
                  <span className="mr-2">
                    <FontAwesomeIcon icon="bars" />
                  </span>
                  &nbsp;Menu
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/">
                    Games
                  </DropdownItem>{' '}
                  <DropdownItem divider />
                  <DropdownItem onClick={this.toggleModal}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>

          {this.renderModal()}
        </Navbar>
      </BodyClassName>
    );
  }
}

Header.propTypes = {
  email: PropTypes.string.isRequired,
};

Header.defaultProps = {};

// Header container

const mapStateToProps = state => ({
  email: state.auth.email,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      dispatch(actions.authLogout());
      ownProps.history.push('/');
      console.log('logged out');
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
