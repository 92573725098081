import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';
import {
  getModelServiceWs,
  // getRootTopic,
  getSimplUrl,
  getSimplAdminUrl,
  getRootTopic,
} from '../../utils/env';

import { getGameData } from '../../utils/gamesData';

import {
  wampOptionsWithDefaults,
  wampSetup,
  newWampSetup,
} from '../../simpl-wamp/utils';
import Progress from '../../simpl-wamp/components/Progress.react';

import {
  taskRunners,
  emailAuthId,
  hideCanvasTasks,
  AUTO_REFRESH_RATE_MS,
} from '../../constants';

import Header from '../../containers/Header';
import Footer from '../../components/Footer';
import BreadcrumbBar from '../../containers/BreadcrumbBar';
import RunTable from '../../containers/RunTable';
import PendingTasks from '../../components/PendingTasks';

import CanvasCreateBtn from './CanvasCreateBtn';
import CanvasSyncBtn from './CanvasSyncBtn';
import CreateRunBtn from './CreateRunBtn';
import HideInactiveRunsToggle from './HideInactiveRunsToggle';

// Displays a game's runs

class GameRunsPage extends React.Component {
  constructor(props) {
    super(props);

    this.timeoutId = 0;
    this.refreshPageState = this.refreshPageState.bind(this);
    this.afterChange = this.afterChange.bind(this);

    this.state = {
      game: [],
      runs: [],
      tasks: [],
      pendingTasks: [],
      hideInactiveRuns: true,
    };
  }

  toggleHideInactiveRuns = () => {
    this.setState(state => ({
      hideInactiveRuns: !state.hideInactiveRuns,
    }));
  };

  refreshPageState = () => {
    const { gameSlug, simplToken, simplAdminToken } = this.props;
    // console.log('refreshPageState: gameSlug:', gameSlug);

    const simplUrl = getSimplUrl();
    axios
      .get(`${simplUrl}/apis/games/?slug=${gameSlug}`, {
        headers: { Authorization: simplToken },
      })
      .then(res => {
        this.setState({
          game: res.data[0],
        });
        // console.log('games:', res.data);

        const url = `${simplUrl}/apis/runs/?game_slug=${gameSlug}`;
        // console.log('url:', url);
        axios
          .get(url, {
            headers: { Authorization: this.props.simplToken },
          })
          .then(res => {
            this.setState({
              runs: res.data,
            });
            // console.log('runs:', res.data);

            const simplAdminUrl = getSimplAdminUrl();
            const url = `${simplAdminUrl}/api/tasks/?game_slug=${gameSlug}&run_id=0`;
            // console.log('game tasks url:', url);
            axios
              .get(url, {
                headers: { Authorization: simplAdminToken },
              })
              .then(res => {
                const tasks = res.data;
                this.setState({
                  tasks: tasks,
                });
                // console.log('tasks:', res.data);

                if (tasks.length > 0) {
                  const url = `${simplAdminUrl}/api/tasks/pending_tasks/?game_slug=${gameSlug}`;
                  axios
                    .get(url, {
                      headers: { Authorization: simplAdminToken },
                    })
                    .then(res => {
                      this.setState({
                        pendingTasks: res.data,
                      });
                      // console.log('pendingTasks:', res.data);
                    });
                }

                this.timeoutId = setTimeout(
                  this.refreshPageState,
                  AUTO_REFRESH_RATE_MS
                );
                // console.log('timeoutId:', this.timeoutId);
              })
              .catch(err => {
                console.log('failed to get game tasks:', err);
              });
          });
      });
  };

  componentDidMount = () => {
    const { gameSlug } = this.props;
    // console.log('componentDidMount: gameSlug:', gameSlug);

    const authId = localStorage.getItem('authId');
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    let options = {
      authid: authId,
      password: 'nopassword',
      url: getModelServiceWs(gameSlug),
      realm: 'realm1',
      prefixes: {},
      progressComponent: Progress,
    };

    const newAuth = emailAuthId.has(gameSlug);
    if (newAuth) {
      options.authid = email;
      options.password = password;
    }
    console.log('options:', options);
    const optionsWithDefaults = wampOptionsWithDefaults(options);

    const rootTopic = getRootTopic(gameSlug);
    console.log('rootTopic:', rootTopic);

    if (newAuth) {
      newWampSetup(this, optionsWithDefaults);
    } else {
      wampSetup(this, optionsWithDefaults);
    }

    this.refreshPageState();
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  afterChange() {
    clearTimeout(this.timeoutId);
    this.refreshPageState();
  }

  render() {
    const { gameSlug, gameData } = this.props;
    // console.log('gameSlug:', gameSlug);
    // console.log('gameData:', gameData);

    const { runs, tasks, pendingTasks, hideInactiveRuns } = this.state;

    let displayedRuns = runs;
    if (hideInactiveRuns) {
      const activeRuns = [];
      runs.forEach(run => {
        if (run.active) {
          activeRuns.push(run);
        }
      });
      displayedRuns = activeRuns;
    }

    let taskAccess = '';
    if (taskRunners.has(gameSlug)) {
      let canvasTasks = '';
      if (!hideCanvasTasks.has(gameSlug)) {
        canvasTasks = (
          <React.Fragment>
            <Row>
              <Col>
                <CanvasCreateBtn
                  gameSlug={gameSlug}
                  afterChange={this.afterChange}
                />
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <CanvasSyncBtn
                  gameSlug={gameSlug}
                  afterChange={this.afterChange}
                />
                <br />
              </Col>
            </Row>
          </React.Fragment>
        );
      }

      taskAccess = (
        <div className="projects-wrapper">
          <Container>
            <Row>
              <Col>
                <PendingTasks
                  tasks={tasks}
                  pendingTasks={pendingTasks}
                  gameSlug={gameSlug}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CreateRunBtn
                  gameSlug={gameSlug}
                  afterChange={this.afterChange}
                />
                <br />
              </Col>
            </Row>
            {canvasTasks}
          </Container>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <BreadcrumbBar />
        <div className="admin-content">
          <Row>
            <Col md="8">
              <div className="projects-wrapper">
                <Container className="menu-bar-inner">
                  <Row>
                    <Col xs="6">
                      <h3 className="table-title text-left">Runs</h3>
                    </Col>
                    <Col xs="6">
                      <span>Hide Inactive Runs</span>{' '}
                      <HideInactiveRunsToggle
                        hideInactiveRuns={hideInactiveRuns}
                        onChange={this.toggleHideInactiveRuns}
                      />
                    </Col>
                  </Row>
                </Container>
                <div className="scroll-table-wrapper">
                  <RunTable
                    runs={displayedRuns}
                    gameData={gameData}
                    gameSlug={gameSlug}
                    afterChange={this.afterChange}
                  />
                </div>
              </div>
            </Col>

            <Col md="4">{taskAccess}</Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { gameSlug } = props.match.params;
  // console.log('GameRunsPage.mapStateToProps: gameSlug:', gameSlug);
  const gameData = getGameData(gameSlug);
  // console.log('GameRunsPage: gameData:', gameData);
  const { simplToken, simplAdminToken } = state.auth;
  // console.log('simplToken:', simplToken);
  // console.log('simplAdminToken:', simplAdminToken);
  return {
    gameSlug,
    gameData,
    simplToken,
    simplAdminToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // TODO
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameRunsPage);
