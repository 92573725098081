import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Container, Row, Col } from 'reactstrap';
import { Form, Field, ErrorMessage, withFormik } from 'formik';

import axios from 'axios';
import { getSimplUrl } from '../utils/env';

class FormikForm extends React.PureComponent {
  render() {
    // console.log('EditRunNameForm: props:', this.props);
    return (
      <Container>
        <Row>
          <Col>
            <Form layout="inline">
              <div className="form-group">
                <Field
                  type="text"
                  name="name"
                  placeholder="Run name"
                  className="form-control"
                />
                <ErrorMessage name="name" />
              </div>
              <Button
                className="btn btn-labeled btn-bay-blue float-right"
                type="submit"
              >
                <span className="btn-label">
                  <FontAwesomeIcon icon="check" />
                </span>{' '}
                Save
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const EditRunNameValidate = (values, props) => {
  const errors = {};
  const name = values.name;
  if (!name) {
    errors.name = 'Please enter a run name.';
  } else {
    const re = new RegExp('[\\w\\-]*');
    const nameLen = name.length;
    const result = name.match(re);
    if (result !== null && result[0].length !== nameLen) {
      errors.name = 'Please use letters, digits, dashes, or underscores.';
    } else {
      let otherName;
      for (otherName of props.otherRunNames) {
        if (otherName === name) {
          errors.name = 'Please enter a unique run name.';
        }
      }
    }
  }
  return errors;
};

const EditRunNameForm = withFormik({
  mapPropsToValues: props => {
    const { run } = props;
    return {
      name: run.name,
    };
  },
  validate: EditRunNameValidate,
  validateOnChange: true,
  validateOnBlur: true,
  handleSubmit: (
    values,
    { props: { loading, error, run, renameRun, simplToken } }
  ) => {
    // console.log('values:', values);
    const name = values.name;
    // console.log('handleSubmit name:', name);
    renameRun(name, simplToken);
  },
})(FormikForm);

// form container

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps:', ownProps);
  const { simplToken } = state.auth;
  return {
    simplToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    renameRun: (name, simplToken) => {
      const { run, afterSubmit } = ownProps;
      // console.log('renameRun: name:', name, ', run:', run);
      const runId = run.id;
      const gameId = run.game;
      const active = run.active;
      const data = run.data;
      const simplUrl = getSimplUrl();
      const url = `${simplUrl}/apis/runs/${runId}/`;
      // console.log('url:', url);
      axios
        .put(
          url,
          {
            game: gameId,
            name: name,
            active: active,
            data: data,
          },
          {
            headers: { Authorization: simplToken },
          }
        )
        .then(res => {
          // console.log(res);
          afterSubmit();
        })
        .catch(error => {
          console.log('renameRun error:', error);
        });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditRunNameForm)
);
