import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import CreateRunForm from '../../containers/CreateRunForm';

class CreateRunBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('CreateRunBtn: openModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('CreateRunBtn: closeModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  handleAfterSubmit = () => {
    const { afterChange } = this.props;
    this.closeModal();
    afterChange();
  };

  renderModal() {
    const { gameSlug } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Create Non-Canvas Run</span>
        </ModalHeader>
        <ModalBody className="text-center">
          <CreateRunForm
            gameSlug={gameSlug}
            afterSubmit={this.handleAfterSubmit}
          />
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <Button className="btn btn-bay-blue" onClick={this.openModal}>
          Create Non-Canvas Run
        </Button>
        {this.renderModal()}
      </div>
    );
  }
}

CreateRunBtn.propTypes = {
  gameSlug: PropTypes.string.isRequired,
  afterChange: PropTypes.func.isRequired,
};

export default CreateRunBtn;
