import React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Table } from 'reactstrap';

import axios from 'axios';
import { getSimplUrl } from '../../utils/env';

import GameRow from './GameRow';

class GameTable extends React.Component {
  state = {
    games: [],
  };

  componentDidMount() {
    axios
      .get(`${getSimplUrl()}/apis/games/`, {
        headers: { Authorization: this.props.simplToken },
      })
      .then(res => {
        this.setState({
          games: res.data,
        });
        // console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { games } = this.state;

    // console.log('GameTable: games: ', games);

    if (games.length === 0) {
      return <div />;
    }

    games.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div>
        <Table striped>
          <thead className="table-wharton-pacific-blue">
            <tr>
              <th width="">Name</th>
              <th width="">Slug</th>
            </tr>
          </thead>

          <tbody>
            {games.map(g => (
              <GameRow key={g.id} game={g} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { simplToken } = state.auth;
  // console.log('simplToken:', simplToken);
  return {
    simplToken,
  };
};

export default withRouter(connect(mapStateToProps, null)(GameTable));
