import axios from 'axios';

import * as actionTypes from './actionTypes';

import { getSimplUrl, getSimplAdminUrl } from '../../utils/env';

import { clearGamesData } from '../../utils/gamesData';

const SESSION_SEC = 3600 * 8; // 60 minutes * 8 == 8 hours
const SESSION_MS = SESSION_SEC * 1000;

export const authStart = () => {
  console.log('dispatch authStart');
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (simplToken, simplAdminToken, authId, email) => {
  console.log('dispatch authSuccess');
  return {
    type: actionTypes.AUTH_SUCCESS,
    simplToken: simplToken,
    simplAdminToken: simplAdminToken,
    authId: authId,
    email: email,
  };
};

export const authFailure = error => {
  console.log('dispatch authFailure');
  return {
    type: actionTypes.AUTH_FAILURE,
    error: error,
  };
};

export const authLogout = () => {
  console.log('dispatch authLogout');

  // logout of Simpl server.
  const simplUrl = getSimplUrl();
  const simplToken = localStorage.getItem('simplToken');
  axios
    .post(`${simplUrl}/rest-auth/logout/`, {
      headers: { Authorization: simplToken },
    })
    .then(simplLogoutResult => {
      // console.log('simplLogoutResult:', simplLogoutResult);

      // logout of simpl-admin server.
      const simplAdminUrl = getSimplAdminUrl();
      const simplAdminToken = localStorage.getItem('simplAdminToken');
      axios
        .post(`${simplAdminUrl}/rest-auth/logout/`, {
          headers: { Authorization: simplAdminToken },
        })
        .then(simplAdminLogoutResult => {
          // console.log('simplAdminLogoutResult:', simplAdminLogoutResult);
        })
        .catch(err => {
          console.log('failed to logout from simpl-admin server');
        });
    })
    .catch(err => {
      console.log('failed to logout from simpl server');
    });

  // remove user's authentication
  localStorage.removeItem('simplToken');
  localStorage.removeItem('simplAdminToken');
  localStorage.removeItem('authId');
  localStorage.removeItem('email');
  localStorage.removeItem('password');
  localStorage.removeItem('expirationDate');

  // remove gamesData cache
  clearGamesData();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = expirationTimeSec => {
  return dispatch => {
    setTimeout(() => {
      dispatch(authLogout());
    }, expirationTimeSec * 1000); // in ms
  };
};

export const authLogin = (email, password) => {
  console.log('dispatch authLogin: email:', email);
  return dispatch => {
    dispatch(authStart());

    // get authentication token for Simpl server.
    const simplUrl = getSimplUrl();
    // console.log('simplUrl:', simplUrl);
    axios
      .post(`${simplUrl}/rest-auth/login/`, {
        username: email,
        email: email,
        password: password,
      })
      .then(simplLoginResult => {
        // console.log('simplLoginResult:', simplLoginResult);
        const simplToken = `Token ${simplLoginResult.data.key}`;

        // verify user is a Simpl staff member
        axios
          .get(`${simplUrl}/apis/users/?email=${email}`, {
            headers: { Authorization: simplToken },
          })
          .then(userResult => {
            // console.log('userResult:', userResult);
            const { is_active, is_staff } = userResult.data[0];
            // console.log('is_active:', is_active, ', is_staff:', is_staff);
            if (is_active && is_staff) {
              const authId = userResult.data[0].id;
              // console.log('authId:', authId);

              // get authentication token for simpl-admin server.
              const simplAdminUrl = getSimplAdminUrl();
              // console.log('simplAdminUrl:', simplAdminUrl);
              axios
                .post(`${simplAdminUrl}/rest-auth/login/`, {
                  username: email,
                  email: email,
                  password: password,
                })
                .then(simplAdminLoginResult => {
                  // console.log('simplAdminLoginResult:', simplAdminLoginResult);
                  const simplAdminToken = `Token ${simplAdminLoginResult.data.key}`;

                  // process authSuccess info
                  const expirationDate = new Date(
                    new Date().getTime() + SESSION_MS
                  ); // in ms
                  localStorage.setItem('simplToken', simplToken);
                  localStorage.setItem('simplAdminToken', simplAdminToken);
                  localStorage.setItem('authId', authId);
                  localStorage.setItem('email', email);
                  localStorage.setItem('password', password);
                  localStorage.setItem('expirationDate', expirationDate);
                  dispatch(
                    authSuccess(simplToken, simplAdminToken, authId, email)
                  );
                  dispatch(checkAuthTimeout(SESSION_SEC)); // in sec
                })
                .catch(err => {
                  console.log('failed to log into simpl-admin server');
                  dispatch(authFailure(err));
                });
            } else {
              console.log('user is not an active staff member');
              dispatch(
                authFailure({ message: 'Access is limited to active staff.' })
              );
            }
          })
          .catch(err => {
            console.log('failed to get simpl-user info');
            dispatch(authFailure(err));
          });
      })
      .catch(err => {
        console.log('failed to log into simpl server');
        dispatch(authFailure(err));
      });
  };
};

export const authCheckState = () => {
  console.log('dispatch authCheckState');
  return dispatch => {
    const simplToken = localStorage.getItem('simplToken');
    if (simplToken === undefined) {
      dispatch(authLogout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(authLogout());
      } else {
        const authId = localStorage.getItem('authId');
        const email = localStorage.getItem('email');
        const simplAdminToken = localStorage.getItem('simplAdminToken');
        dispatch(authSuccess(simplToken, simplAdminToken, authId, email));
        dispatch(checkAuthTimeout(SESSION_SEC)); // in sec
      }
    }
  };
};
