import moment from 'moment-timezone';

// given milliseconds, return date string for display
export function getNYDisplayDateTime(dateTimeString) {
  return moment(dateTimeString)
    .tz('America/New_York')
    .format('MM/DD/YYYY hh:mm A z');
}

// given milliseconds, return date string for display
export function getNYDisplayDate(dateTimeString) {
  return moment(dateTimeString)
    .tz('America/New_York')
    .format('MM/DD/YYYY');
}
