import axios from 'axios';
import { getSimplUrl } from './env';

const configGameData = (game, phases, roles) => {
  const gameId = game.id;
  const gamePhases = {};
  phases.forEach(phase => {
    // console.log('phase:', phase);
    if (phase.game === gameId) {
      gamePhases[phase.id] = phase;
    }
  });
  const gameRoles = {};
  roles.forEach(role => {
    // console.log('role:', role);
    if (role.game === gameId) {
      gameRoles[role.id] = role;
    }
  });
  const gameData = {
    name: game.name,
    phases: gamePhases,
    roles: gameRoles,
  };
  return gameData;
};

export const initGamesData = simplToken => {
  console.log('initGamesData');
  const url = getSimplUrl();
  axios
    .get(`${url}/apis/games/ `, { headers: { Authorization: simplToken } })
    .then(res => {
      const games = res.data;
      // console.log(res.data);

      axios
        .get(`${url}/apis/phases/ `, {
          headers: { Authorization: simplToken },
        })
        .then(res => {
          const phases = res.data;
          // console.log(res.data);

          axios
            .get(`${url}/apis/roles/ `, {
              headers: { Authorization: simplToken },
            })
            .then(res => {
              const roles = res.data;
              // console.log(res.data);

              const gamesData = {};
              games.forEach(game => {
                gamesData[game.slug] = configGameData(game, phases, roles);
              });

              // console.log('set gamesData: ', gamesData);
              localStorage.setItem('gamesData', JSON.stringify(gamesData));
            });
        });
    });
};

export const getGamesData = () => {
  // console.log('getGamesData');
  const gamesData = localStorage.getItem('gamesData');
  if (gamesData !== null) {
    // console.log('gamesData:', gamesData);
    const parsed = JSON.parse(gamesData);
    // console.log(' parsed gamesData:', parsed);
    return parsed;
  } else {
    return {};
  }
};

export const getGameData = gameSlug => {
  // console.log('getGameData: gameSlug:', gameSlug);
  const gamesData = getGamesData();
  let gameData = {};
  if (gamesData !== null && gameSlug !== null) {
    // console.log('getGameData: gamesData:', gamesData);
    gameData = gamesData[gameSlug];
  }
  // console.log('getGameData: gameData:', gameData);
  return gameData;
};

export const clearGamesData = () => {
  console.log('clearGamesData');
  localStorage.removeItem('gamesData');
};
