import React from 'react';

import { Button } from 'reactstrap';
import { Form, Field, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';

class LoginForm extends React.PureComponent {
  render() {
    return (
      <Form>
        <div className="login-form">
          <div className="form-group">
            <Field
              type="text"
              name="email"
              placeholder="Email address"
              className="form-control"
            />
            <ErrorMessage name="email" />
          </div>
          <div className="form-group">
            <Field
              type="password"
              name="password"
              placeholder="Password"
              className="form-control"
            />
            <ErrorMessage name="password" />
          </div>
          <Button
            type="submit"
            className="btn btn-pacific-blue btn-lg btn-block"
          >
            Log in
          </Button>
        </div>
      </Form>
    );
  }
}

const LoginValidation = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
  password: yup.string().required('Please enter a password.'),
});

export default withFormik({
  mapPropsToValues: props => {
    return {
      email: '',
      password: '',
    };
  },
  validationSchema: LoginValidation,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  handleSubmit: (values, { props: { loading, error, onAuth } }) => {
    onAuth(values.email, values.password);
    console.log('logged in');
  },
})(LoginForm);
