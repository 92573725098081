import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import Switch from 'react-switch';
import { getSimplUrl } from '../../utils/env';
import axios from 'axios';

class StatusToggle extends React.Component {
  constructor() {
    super();
    this.state = { active: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = active => {
    // console.log('handleChange: active -> ', active);
    this.setState({ active });
    const { simplToken, toggleStatus } = this.props;
    toggleStatus(active, simplToken);
  };

  componentDidMount() {
    const { run } = this.props;
    this.setState({ active: run.active });
  }

  render() {
    return (
      <label className="status-switch" htmlFor="status-switch">
        <Switch
          onChange={this.handleChange}
          checked={this.state.active}
          height={26}
          width={75}
          checkedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                height: '100%',
                fontSize: 15,
                color: 'white',
                paddingRight: 2,
              }}
            >
              &nbsp;&nbsp;&nbsp;Yes
            </div>
          }
          uncheckedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                fontSize: 15,
                color: 'white',
                paddingRight: 2,
              }}
            >
              No
            </div>
          }
          className="react-switch"
          id="status-switch"
        />
      </label>
    );
  }
}

StatusToggle.propTypes = {
  run: PropTypes.object.isRequired,
  toggleStatus: PropTypes.func.isRequired,
  afterChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps:', ownProps);
  const { simplToken } = state.auth;
  // console.log('typeof simplToken ===', typeof simplToken);
  return {
    simplToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleStatus: (active, simplToken) => {
      const { run, afterChange } = ownProps;
      // console.log('toggleStatus: active:', active);
      const runId = run.id;
      const gameId = run.game;
      const name = run.name;
      const data = run.data;
      const simplUrl = getSimplUrl();
      const url = `${simplUrl}/apis/runs/${runId}/`;
      // console.log('url:', url);
      axios
        .put(
          url,
          {
            game: gameId,
            name: name,
            active: active,
            data: data,
          },
          {
            headers: { Authorization: simplToken },
          }
        )
        .then(res => {
          // console.log(res);
          afterChange();
        })
        .catch(error => {
          console.log('toggleStatus error:', error);
        });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatusToggle)
);
