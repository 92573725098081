import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

class PendingTasks extends React.Component {
  render() {
    const { tasks, pendingTasks, gameSlug, runId } = this.props;

    let pendingTasksNotice = <h1>No Tasks</h1>;
    if (tasks.length > 0) {
      const message = `Tasks (${pendingTasks.length} Pending)`;

      const linkDestination =
        runId === null
          ? `/games/${gameSlug}/tasks/`
          : `/games/${gameSlug}/runs/${runId}/tasks/`;
      pendingTasksNotice = (
        <Link to={linkDestination} className="float-left">
          <h1>{message}</h1>
        </Link>
      );
    }

    return pendingTasksNotice;
  }
}

PendingTasks.propTypes = {
  tasks: PropTypes.array.isRequired,
  pendingTasks: PropTypes.array.isRequired,
  gameSlug: PropTypes.string.isRequired,
  runId: PropTypes.number,
};

PendingTasks.defaultProps = {
  runId: null,
};

export default PendingTasks;
