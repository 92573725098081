export function getPrevPhase(phases, phase) {
  // console.log('getPrevPhase: phases:', phases, ', phase:', phase);
  const phaseOrder = phase.order;
  const phaseArray = Object.values(phases);
  for (let i = 0; i < phaseArray.length; i++) {
    const p = phaseArray[i];
    if (p.order === phaseOrder - 1) {
      return p;
    }
  }
  return null;
}

export function getNextPhase(phases, phase) {
  // console.log('getNextPhase: phases:', phases, ', phase:', phase);
  const phaseOrder = phase.order;
  const phaseArray = Object.values(phases);
  for (let i = 0; i < phaseArray.length; i++) {
    const p = phaseArray[i];
    if (p.order === phaseOrder + 1) {
      return p;
    }
  }
  return null;
}
