import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Container, Row, Col } from 'reactstrap';
import { Form, Field, ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';

import axios from 'axios';
import { getSimplAdminUrl } from '../utils/env';

import * as actions from '../store/actions/wamp';

class FormikForm extends React.PureComponent {
  render() {
    // console.log('CanvasSyncForm: props:', this.props);
    return (
      <Container>
        <Row>
          <Col>
            <Form layout="inline">
              <div className="form-group">
                <Field
                  type="number"
                  name="courseId"
                  placeholder="Canvas course id"
                  className="form-control"
                />
                <ErrorMessage name="courseId" />
              </div>
              <Button
                className="btn btn-labeled btn-bay-blue float-right"
                type="submit"
              >
                <span className="btn-label">
                  <FontAwesomeIcon icon="play" />
                </span>{' '}
                Start Task
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <span>
              <strong>NOTE</strong> tasks may take a few minutes to complete.
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}

const CanvasSyncValidation = yup.object().shape({
  courseId: yup
    .number()
    .min(1)
    .required('Please enter a Canvas course id.'),
});

const CanvasSyncForm = withFormik({
  mapPropsToValues: props => {
    return {
      courseId: '',
    };
  },
  validationSchema: CanvasSyncValidation,
  validateOnChange: true,
  validateOnBlur: true,
  handleSubmit: (
    values,
    { props: { loading, error, syncCanvasRuns, simplAdminToken } }
  ) => {
    // console.log('values:', values);
    const courseId = values.courseId;
    // console.log('handleSubmit courseId:', courseId);
    syncCanvasRuns(courseId, simplAdminToken);
  },
})(FormikForm);

// form container

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps:', ownProps);
  const { simplAdminToken } = state.auth;
  return {
    simplAdminToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    syncCanvasRuns: (courseId, simplAdminToken) => {
      const { gameSlug, afterSubmit } = ownProps;
      // console.log('syncCanvasRuns: gameSlug:', gameSlug);
      dispatch(actions.canvasSync(gameSlug, courseId))
        .then(result => {
          const { payload } = result;
          const { celery_task_id, error } = payload;
          console.log(
            'canvasSync celery_task_id:',
            celery_task_id,
            ', error:',
            error
          );
          if (typeof error === 'undefined') {
            // add task to database
            axios
              .post(
                `${getSimplAdminUrl()}/api/tasks/`,
                {
                  game_slug: gameSlug,
                  task_name: 'canvas_sync',
                  celery_task_id: celery_task_id,
                },
                { headers: { Authorization: simplAdminToken } }
              )
              .then(res => {
                // console.log(res);
                afterSubmit();
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(error => {
          console.log('syncCanvasRuns error:', error);
        });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CanvasSyncForm)
);
