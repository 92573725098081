import React from 'react';

import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';

import { getSimplAdminUrl } from '../utils/env';

import { AUTO_REFRESH_RATE_MS } from '../constants';

import Header from '../containers/Header';
import Footer from '../components/Footer';
import BreadcrumbBar from '../containers/BreadcrumbBar';
import TaskTable from '../containers/TaskTable';

// Displays a game's tasks

class GameTasksPage extends React.Component {
  constructor(props) {
    super(props);

    this.timeoutId = 0;
    this.refreshPageState = this.refreshPageState.bind(this);

    this.state = {
      tasks: [],
    };
  }

  refreshPageState = () => {
    const { simplAdminToken } = this.props;
    const { gameSlug } = this.props.match.params;

    const url = `${getSimplAdminUrl()}/api/tasks/?game_slug=${gameSlug}&run_id=0`;
    // console.log('url:', url);
    axios
      .get(url, { headers: { Authorization: simplAdminToken } })
      .then(res => {
        this.setState({
          tasks: res.data,
        });
        // console.log('tasks:', res.data);

        this.timeoutId = setTimeout(
          this.refreshPageState,
          AUTO_REFRESH_RATE_MS
        );
        // console.log('timeoutId:', this.timeoutId);
      });
  };

  componentDidMount() {
    this.refreshPageState();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const { tasks } = this.state;
    const { gameSlug } = this.props.match.params;

    let taskTable = <h1>There are no Tasks</h1>;
    if (tasks.length > 0) {
      taskTable = (
        <div className="projects-wrapper">
          <Container className="menu-bar-inner">
            <Row>
              <Col xs="6">
                <h3 className="table-title text-left">Tasks</h3>
              </Col>
            </Row>
          </Container>
          <div className="scroll-table-wrapper">
            <TaskTable tasks={tasks} gameSlug={gameSlug} />
          </div>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <BreadcrumbBar />
        <div className="admin-content">
          <Row>
            <Col md="11">{taskTable}</Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { simplAdminToken } = state.auth;
  return {
    simplAdminToken,
  };
};

export default connect(mapStateToProps, null)(GameTasksPage);
