import AutobahnReact from '../../simpl-wamp/autobahn';
import { createAction } from 'redux-actions';

import { ROOT_TOPIC_ROOT } from '../../constants';

import * as actionTypes from './actionTypes';

/**
 * Set the wamp connection status on the store.
 * @function
 * @param {symbol} status - the connection status.
 * @returns {NamedReduxAction}
 */
export const setConnectionStatus = createAction(
  actionTypes.SET_CONNECTION_STATUS
);

export const helloGame = createAction('HELLO_GAME', gameSlug => {
  const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.hello_game`;
  console.log('helloGame url:', url);
  AutobahnReact.publish(url);
});

export const createRun = createAction('CREATE_RUN', (gameSlug, runName) => {
  const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.create_run`;
  console.log('createRun url:', url);
  return AutobahnReact.call(url, [runName]);
});

export const canvasCreate = createAction(
  'CANVAS_CREATE',
  (gameSlug, courseId) => {
    const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.canvas_create`;
    console.log('canvasCreate url:', url);
    return AutobahnReact.call(url, [courseId]);
  }
);

export const canvasSync = createAction('CANVAS_SYNC', (gameSlug, courseId) => {
  const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.canvas_sync`;
  console.log('canvasSync url:', url);
  return AutobahnReact.call(url, [courseId]);
});

export const deleteRunUser = createAction(
  'DELETE_RUN_USER',
  (gameSlug, runName, email) => {
    const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.delete_run_user`;
    console.log('deleteRunUser url:', url);
    return AutobahnReact.call(url, [runName, email]);
  }
);

export const addLeaderToRun = createAction(
  'ADD_LEADER_TO_RUN',
  (gameSlug, runName, email) => {
    const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.add_leader_to_run`;
    console.log('addLeaderToRun url:', url);
    return AutobahnReact.call(url, [runName, email]);
  }
);

export const addPlayersToRun = createAction(
  'ADD_PLAYERS_TO_RUN',
  (gameSlug, runName, playerJson) => {
    const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.add_players_to_run`;
    console.log('addPlayersToRun url:', url);
    return AutobahnReact.call(url, [runName, playerJson]);
  }
);

export const changeRunPhase = createAction(
  'CHANGE_RUN_PHASE',
  (gameSlug, runName, phaseName) => {
    const url = `${ROOT_TOPIC_ROOT}${gameSlug}.model.game.change_run_phase`;
    console.log('changeRunPhase url:', url);
    return AutobahnReact.call(url, [runName, phaseName]);
  }
);
