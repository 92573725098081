import React from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import LoginPage from './pages/LoginPage';

import GamesPage from './pages/GamesPage';
import GameRunsPage from './pages/GameRunsPage';
import GameTasksPage from './pages/GameTasksPage';

import RunPage from './pages/RunPage';
import RunTasksPage from './pages/RunTasksPage';
import TaskPage from './pages/TaskPage';

const history = createBrowserHistory();

class AppRouter extends React.Component {
  render() {
    console.log('AppRouter: isAuthenticated:', this.props.isAuthenticated);
    if (!this.props.isAuthenticated) {
      console.log('not authenticated -> login');
      return (
        <div>
          <Route path={'/'} component={LoginPage} />
        </div>
      );
    } else {
      console.log('authenticated -> route');
      // prettier-ignore
      return (
        <Router history={history} onUpdate={this.hashLinkScroll}>
          <Route exact path={'/'} component={GamesPage} />
          <Route exact path={'/login/'} component={LoginPage} />

          <Route exact path={'/games/'} component={GamesPage} />
          <Route exact path={'/games/:gameSlug/runs/'} component={GameRunsPage} />
          <Route exact path={'/games/:gameSlug/runs/:runId'} component={RunPage} />

          <Route exact path={'/games/:gameSlug/tasks/'} component={GameTasksPage} />
          <Route exact path={'/games/:gameSlug/tasks/:taskId'} component={TaskPage} />

          <Route exact path={'/games/:gameSlug/runs/:runId/tasks'} component={RunTasksPage} />
          <Route exact path={'/games/:gameSlug/runs/:runId/tasks/:taskId'} component={TaskPage} />
        </Router>
      );
    }
  }
}

export default AppRouter;
