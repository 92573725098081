import React from 'react';

import PropTypes from 'prop-types';

import Switch from 'react-switch';

class HideInactiveRunsToggle extends React.Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = hide => {
    console.log('handleChange: hide -> ', hide);
    const { onChange } = this.props;
    onChange();
  };

  render() {
    const { hideInactiveRuns } = this.props;
    return (
      <label className="status-switch" htmlFor="status-switch">
        <Switch
          onChange={this.handleChange}
          checked={hideInactiveRuns}
          height={26}
          width={75}
          checkedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                height: '100%',
                fontSize: 15,
                color: 'white',
                paddingRight: 2,
              }}
            >
              &nbsp;&nbsp;&nbsp;Yes
            </div>
          }
          uncheckedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                fontSize: 15,
                color: 'white',
                paddingRight: 2,
              }}
            >
              No
            </div>
          }
          className="react-switch"
          id="status-switch"
        />
      </label>
    );
  }
}

HideInactiveRunsToggle.propTypes = {
  hideInactiveRuns: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HideInactiveRunsToggle;
