import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getGameData } from '../utils/gamesData';

const HOME_PATH = '/';
const GAMES_PATH = '/games/';
const GAME_RUNS_PATH = '/games/:gameSlug/runs/';
const GAME_TASKS_PATH = '/games/:gameSlug/tasks/';
const TASK_PATH = '/games/:gameSlug/tasks/:taskId';
const RUN_PATH = '/games/:gameSlug/runs/:runId';
const RUN_TASKS_PATH = '/games/:gameSlug/runs/:runId/tasks';
const RUN_TASK_PATH = '/games/:gameSlug/runs/:runId/tasks/:taskId';

/*
Breadcrumb bar used at head of pages.
Constructs a series of breadcrumb links to parent pages based on the current page URL.
*/
const BreadcrumbBar = props => {
  const { runName, taskName, match, gameData } = props;
  // console.log('BreadcrumbBar.props.match:', match);
  // console.log('BreadcrumbBar.props.gameData:', gameData);

  const { path, params } = match;
  // console.log('BreadcrumbBar path:', path, ', params:', params);

  const breadcrumbs = [];
  if (!path.localeCompare(HOME_PATH) || !path.localeCompare(GAMES_PATH)) {
    breadcrumbs.push({ link: false, title: 'Games' });
    //
  } else {
    breadcrumbs.push({ link: true, to: HOME_PATH, title: 'Games' });

    if (!path.localeCompare(GAME_RUNS_PATH)) {
      breadcrumbs.push({ link: false, title: gameData.name });
      //
    } else {
      const { gameSlug } = params;
      const gamePath = GAME_RUNS_PATH.replace(':gameSlug', gameSlug);
      breadcrumbs.push({ link: true, to: gamePath, title: gameData.name });

      if (!path.localeCompare(GAME_TASKS_PATH)) {
        breadcrumbs.push({ link: false, title: 'Tasks' });
        //
      } else if (!path.localeCompare(TASK_PATH)) {
        const tasksPath = GAME_TASKS_PATH.replace(':gameSlug', gameSlug);
        breadcrumbs.push({ link: true, to: tasksPath, title: 'Tasks' });
        breadcrumbs.push({ link: false, title: taskName });
        //
      } else if (!path.localeCompare(RUN_PATH)) {
        breadcrumbs.push({ link: false, title: runName });
        //
      } else {
        const { runId } = params;
        const runPath = RUN_PATH.replace(':gameSlug', gameSlug).replace(
          ':runId',
          runId
        );

        if (!path.localeCompare(RUN_TASKS_PATH)) {
          breadcrumbs.push({ link: true, to: runPath, title: runName });
          breadcrumbs.push({ link: false, title: 'Tasks' });
          //
        } else if (!path.localeCompare(RUN_TASK_PATH)) {
          const runTasksPath = RUN_TASKS_PATH.replace(
            ':gameSlug',
            gameSlug
          ).replace(':runId', runId);

          breadcrumbs.push({ link: true, to: runPath, title: runName });
          breadcrumbs.push({ link: true, to: runTasksPath, title: 'Tasks' });
          breadcrumbs.push({ link: false, title: taskName });
        }
      }
    }
  }

  const titleElements = breadcrumbs.map((breadcrumb, index, array) => {
    const maxIndex = array.length - 1;
    const separator = index < maxIndex ? ' > ' : ' ';
    if (breadcrumb.link) {
      return (
        <React.Fragment key={index}>
          <Link to={breadcrumb.to} className="breadcrumb-link">
            {breadcrumb.title}
          </Link>
          <span>{separator}</span>
        </React.Fragment>
      );
    } else {
      return (
        <span key={index}>
          {breadcrumb.title} {separator}
        </span>
      );
    }
  });

  return (
    <div className="admin-menu-bar bg-wharton-pacific-blue d-flex align-items-center">
      <div className="flex-fill">{titleElements}</div>
    </div>
  );
};

BreadcrumbBar.propTypes = {
  runName: PropTypes.string,
  taskName: PropTypes.string,
  gameData: PropTypes.object,
};

BreadcrumbBar.defaultProps = {
  runName: '',
  taskName: '',
  gameData: {},
};

const mapStateToProps = (state, props) => {
  const { gameSlug } = props.match.params;
  const gameData = getGameData(gameSlug);
  // console.log('BreadcrumbBar: gameSlug:', gameSlug, ', gameData:', gameData);

  return {
    gameData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // TODO
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BreadcrumbBar)
);
