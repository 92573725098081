import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  simplToken: null,
  simplAdminToken: null,
  authId: null, // Simpl user id for wamp requests
  email: null, // Displayed in header
  error: null,
  loading: false,
};

const authStart = (state, action) => {
  console.log('reducing authStart: action:', action);
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  console.log('reducing authSuccess: action:', action);
  return updateObject(state, {
    simplToken: action.simplToken,
    simplAdminToken: action.simplAdminToken,
    authId: action.authId,
    email: action.email,
    error: null,
    loading: false,
  });
};

const authFailure = (state, action) => {
  console.log('reducing authFailure: action:', action);
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  console.log('reducing authLogout: action:', action);
  return updateObject(state, {
    simplToken: null,
    simplAdminToken: null,
    authId: null,
    email: null,
  });
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAILURE:
      return authFailure(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    default:
      return state;
  }
};

export default auth;
