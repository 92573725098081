import React from 'react';

import PropTypes from 'prop-types';

import { Table } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RunRow from './RunRow';

const NAME = 1;
const COURSE_ID = 2;
const PHASE = 3;
const STATUS = 4;
const CREATED = 5;

class RunTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: NAME,
    };
  }

  selectSortByName = () => {
    this.setState({ sortBy: NAME });
  };
  selectSortByCourseID = () => {
    this.setState({ sortBy: COURSE_ID });
  };
  selectSortByPhase = () => {
    this.setState({ sortBy: PHASE });
  };
  selectSortByStatus = () => {
    this.setState({ sortBy: STATUS });
  };
  selectSortByCreated = () => {
    this.setState({ sortBy: CREATED });
  };

  columnText = code => {
    switch (code) {
      case NAME:
        return 'Name';
      case COURSE_ID:
        return 'Course ID';
      case PHASE:
        return 'Phase';
      case STATUS:
        return 'Status';
      case CREATED:
        return 'Created';
      default:
        return '';
    }
  };

  columnHeader = code => {
    const { sortBy } = this.state;
    const selected = code === sortBy;
    let text = this.columnText(code);

    let header = '';
    if (selected) {
      const icon = code !== CREATED ? 'chevron-down' : 'chevron-up';
      header = (
        <span>
          <FontAwesomeIcon icon={icon} /> {text}
        </span>
      );
    } else {
      switch (code) {
        case NAME: {
          header = (
            <span role="button" onClick={this.selectSortByName}>
              {text}
            </span>
          );
          break;
        }
        case COURSE_ID: {
          header = (
            <span role="button" onClick={this.selectSortByCourseID}>
              {text}
            </span>
          );
          break;
        }
        case PHASE: {
          header = (
            <span role="button" onClick={this.selectSortByPhase}>
              {text}
            </span>
          );
          break;
        }
        case STATUS: {
          header = (
            <span role="button" onClick={this.selectSortByStatus}>
              {text}
            </span>
          );
          break;
        }
        case CREATED: {
          header = (
            <span role="button" onClick={this.selectSortByCreated}>
              {text}
            </span>
          );
          break;
        }
        default:
          header = '';
      }
    }
    return header;
  };

  render() {
    const { runs, gameData, gameSlug, afterChange } = this.props;
    // console.log('RunTable: runs:', runs);
    // console.log('RunTable: gameData:', gameData);

    if (runs.length === 0 || typeof gameData === 'undefined') {
      return <div />;
    }

    const { sortBy } = this.state;
    switch (sortBy) {
      case NAME: {
        runs.sort((a, b) => a.name.localeCompare(b.name));
        break;
      }
      case COURSE_ID: {
        runs.sort((a, b) => {
          if (
            typeof a.data.canvas !== 'undefined' &&
            typeof b.data.canvas !== 'undefined' &&
            typeof a.data.canvas.course_id !== 'undefined' &&
            typeof b.data.canvas.course_id !== 'undefined'
          ) {
            const aCourseId = a.data.canvas.course_id;
            const bCourseId = b.data.canvas.course_id;
            return aCourseId.localeCompare(bCourseId);
          } else if (
            typeof a.data.canvas !== 'undefined' &&
            typeof a.data.canvas.course_id !== 'undefined'
          ) {
            return -1;
          } else if (
            typeof b.data.canvas !== 'undefined' &&
            typeof b.data.canvas.course_id !== 'undefined'
          ) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case PHASE: {
        runs.sort((a, b) =>
          gameData.phases[a.phase].name.localeCompare(
            gameData.phases[b.phase].name
          )
        );
        break;
      }
      case STATUS: {
        runs.sort((a, b) => String(b.active).localeCompare(String(a.active))); // actives first
        break;
      }
      case CREATED: {
        runs.sort((a, b) => b.created.localeCompare(a.created)); // recents first
        break;
      }
      default:
    }

    const nameHeader = this.columnHeader(NAME);
    const courseIdHeader = this.columnHeader(COURSE_ID);
    const phaseHeader = this.columnHeader(PHASE);
    const statusHeader = this.columnHeader(STATUS);
    const createdHeader = this.columnHeader(CREATED);

    return (
      <div>
        <Table striped>
          <thead className="table-wharton-pacific-blue">
            <tr>
              <th width="">{nameHeader}</th>
              <th width="">{courseIdHeader}</th>
              <th width="">{phaseHeader}</th>
              <th width="">{statusHeader}</th>
              <th width="">{createdHeader}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {runs.map(r => (
              <RunRow
                key={r.id}
                run={r}
                gameData={gameData}
                gameSlug={gameSlug}
                afterDelete={afterChange}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

RunTable.defaultProps = {
  runs: PropTypes.array.isRequired,
  gameData: PropTypes.object.isRequired,
  gameSlug: PropTypes.string.isRequired,
  afterChange: PropTypes.func.isRequired,
};

export default RunTable;
