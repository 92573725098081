import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getNYDisplayDateTime } from '../../utils/datetime';

class TaskRow extends React.Component {
  render() {
    const { task, gameSlug, runId } = this.props;
    const { id, created, task_name, task_result } = task;

    // const name = `${game_slug}.${task_name}`;
    const created_date = getNYDisplayDateTime(created);

    let status = 'PENDING';
    if (task_result !== null) {
      const linkDestination =
        runId === null
          ? `/games/${gameSlug}/tasks/${id}`
          : `/games/${gameSlug}/runs/${runId}/tasks/${id}`;
      const label =
        task_result.status !== 'STATUS' ? 'COMPLETED' : task_result.status;
      status = <Link to={linkDestination}> {label} </Link>;
    }

    let args = '';
    if (task_result !== null) {
      const { task_args } = task_result;
      const len = task_args.length;
      if (len <= 50) {
        args = task_args.slice(0, len);
      } else {
        args = `${task_args.slice(0, 49)} ...`;
      }
    }

    return (
      <tr id={id}>
        <td>{created_date}</td>
        <td>{task_name}</td>
        <td>{args}</td>
        <td>{status}</td>
      </tr>
    );
  }
}

TaskRow.propTypes = {
  task: PropTypes.object.isRequired,
  gameSlug: PropTypes.string.isRequired,
  runId: PropTypes.string,
};

TaskRow.defaultProps = {
  runId: null,
};

export default TaskRow;
