import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { connect } from 'react-redux';

// select Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEdit,
  faFileDownload,
  faFileUpload,
  faPen,
  faPlay,
  faPlus,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import './App.scss';

import AppRouter from './routes';

import * as authActions from './store/actions/auth';

// build Font Awesome icon library
library.add(
  faBars,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEdit,
  faFileUpload,
  faFileDownload,
  faPen,
  faPlay,
  faPlus,
  faTimes,
  faTrashAlt
);

class App extends React.Component {
  componentDidMount() {
    this.props.verifyAuthorized();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <AppRouter {...this.props} />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.simplToken !== null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyAuthorized: () => dispatch(authActions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
