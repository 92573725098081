import { createAction } from 'redux-actions';

export const recyleStateAction = createAction('RECYCLE_STATE');

/**
 * @namespace state
 */
export default {
  recyleStateAction,
};
