// constants shared between actions and reducers.

// auth actions
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// wamp actions
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
