import React from 'react';

import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';
import { getSimplUrl, getSimplAdminUrl } from '../utils/env';

import { AUTO_REFRESH_RATE_MS } from '../constants';

import Header from '../containers/Header';
import Footer from '../components/Footer';
import BreadcrumbBar from '../containers/BreadcrumbBar';
import TaskTable from '../containers/TaskTable';

// Displays a run's tasks

class RunTasksPage extends React.Component {
  constructor(props) {
    super(props);

    this.timeoutId = 0;
    this.refreshPageState = this.refreshPageState.bind(this);

    this.state = {
      run: null,
      tasks: [],
    };
  }

  refreshPageState = () => {
    const { simplToken, simplAdminToken } = this.props;

    const { runId } = this.props.match.params;
    // console.log('runId:', runId);
    const simplUrl = getSimplUrl();

    const runUrl = `${simplUrl}/apis/runs/${runId}/`;
    axios
      .get(runUrl, {
        headers: { Authorization: simplToken },
      })
      .then(res => {
        this.setState({
          run: res.data,
        });
        // console.log('run: res.data:', res.data);

        const tasksUrl = `${getSimplAdminUrl()}/api/tasks/?run_id=${runId}`;
        // console.log('url:', url);
        axios
          .get(tasksUrl, { headers: { Authorization: simplAdminToken } })
          .then(res => {
            this.setState({
              tasks: res.data,
            });
            // console.log('tasks:', res.data);

            this.timeoutId = setTimeout(
              this.refreshPageState,
              AUTO_REFRESH_RATE_MS
            );
            // console.log('timeoutId:', this.timeoutId);
          });
      });
  };

  componentDidMount() {
    this.refreshPageState();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const { run, tasks } = this.state;
    const { gameSlug, runId } = this.props.match.params;

    if (run === null) {
      return (
        <div>
          <Header />
          <Footer />
        </div>
      );
    }

    let taskTable = <h1>There are no Tasks</h1>;
    if (tasks.length > 0) {
      taskTable = (
        <div className="projects-wrapper">
          <Container className="menu-bar-inner">
            <Row>
              <Col xs="6">
                <h3 className="table-title text-left">Tasks</h3>
              </Col>
            </Row>
          </Container>
          <div className="scroll-table-wrapper">
            <TaskTable
              tasks={tasks}
              gameSlug={gameSlug}
              runId={runId}
              runName={run.name}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <Header />
        <BreadcrumbBar runName={run.name} />
        <div className="admin-content">
          <Row>
            <Col md="11">{taskTable}</Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { simplToken, simplAdminToken } = state.auth;
  return {
    simplToken,
    simplAdminToken,
  };
};

export default connect(mapStateToProps, null)(RunTasksPage);
