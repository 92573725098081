import React from 'react';
import { Table } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RunUserRow from './RunUserRow';

const LAST_NAME = 1;
const FIRST_NAME = 2;
const EMAIL = 3;
const ACCESS = 4;
const WORLD = 5;
const ROLE = 6;
const ADDED = 7;

class RunUserTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: LAST_NAME,
    };
  }

  selectSortByLastName = () => {
    this.setState({ sortBy: LAST_NAME });
  };
  selectSortByFirstName = () => {
    this.setState({ sortBy: FIRST_NAME });
  };
  selectSortByEmail = () => {
    this.setState({ sortBy: EMAIL });
  };
  selectSortByAccess = () => {
    this.setState({ sortBy: ACCESS });
  };
  selectSortByWorld = () => {
    this.setState({ sortBy: WORLD });
  };
  selectSortByRole = () => {
    this.setState({ sortBy: ROLE });
  };
  selectSortByAdded = () => {
    this.setState({ sortBy: ADDED });
  };

  columnText = code => {
    switch (code) {
      case LAST_NAME:
        return 'Last Name';
      case FIRST_NAME:
        return 'First Name';
      case EMAIL:
        return 'Email';
      case ACCESS:
        return 'Access';
      case WORLD:
        return 'World';
      case ROLE:
        return 'Role';
      case ADDED:
        return 'Added';
      default:
        return '';
    }
  };

  columnHeader = code => {
    const { sortBy } = this.state;
    const selected = code === sortBy;
    let text = this.columnText(code);

    let header = '';
    if (selected) {
      const icon = code !== ADDED ? 'chevron-down' : 'chevron-up';
      header = (
        <span>
          <FontAwesomeIcon icon={icon} /> {text}
        </span>
      );
    } else {
      switch (code) {
        case LAST_NAME: {
          header = (
            <span role="button" onClick={this.selectSortByLastName}>
              {text}
            </span>
          );
          break;
        }
        case FIRST_NAME: {
          header = (
            <span role="button" onClick={this.selectSortByFirstName}>
              {text}
            </span>
          );
          break;
        }
        case EMAIL: {
          header = (
            <span role="button" onClick={this.selectSortByEmail}>
              {text}
            </span>
          );
          break;
        }
        case ACCESS: {
          header = (
            <span role="button" onClick={this.selectSortByAccess}>
              {text}
            </span>
          );
          break;
        }
        case WORLD: {
          header = (
            <span role="button" onClick={this.selectSortByWorld}>
              {text}
            </span>
          );
          break;
        }
        case ROLE: {
          header = (
            <span role="button" onClick={this.selectSortByRole}>
              {text}
            </span>
          );
          break;
        }
        case ADDED: {
          header = (
            <span role="button" onClick={this.selectSortByAdded}>
              {text}
            </span>
          );
          break;
        }
        default:
      }
    }

    return header;
  };

  render() {
    const {
      runusers,
      worlds,
      lti,
      gameSlug,
      runName,
      afterChange,
    } = this.props;
    // console.log('RunUserTable: runusers:', runusers);
    // console.log('RunUserTable: worlds:', worlds);
    // console.log('RunUserTable: lti:', lti);

    if (runusers.length === 0) {
      return <div />;
    }

    const { sortBy } = this.state;
    switch (sortBy) {
      case LAST_NAME: {
        runusers.sort((a, b) => a.last_name.localeCompare(b.last_name));
        break;
      }
      case FIRST_NAME: {
        runusers.sort((a, b) => a.first_name.localeCompare(b.first_name));
        break;
      }
      case EMAIL: {
        runusers.sort((a, b) => a.email.localeCompare(b.email));
        break;
      }
      case ACCESS: {
        runusers.sort((a, b) => {
          if (a.leader === b.leader) {
            return 0;
          } else if (a.leader) {
            return -1;
          } else {
            return 1;
          }
        });
        break;
      }
      case WORLD: {
        runusers.sort((a, b) => {
          if (a.world === b.world) {
            return 0;
          } else if (typeof a.world === 'undefined' || a.world === null) {
            return -1;
          } else if (typeof b.world === 'undefined' || b.world === null) {
            return 1;
          } else {
            let aWorld = '';
            if (!a.leader && worlds.length > 0) {
              worlds.forEach(w => {
                if (a.world === w.id) {
                  aWorld = w.name;
                }
              });
            }
            let bWorld = '';
            if (!b.leader && worlds.length > 0) {
              worlds.forEach(w => {
                if (b.world === w.id) {
                  bWorld = w.name;
                }
              });
            }
            return aWorld.localeCompare(bWorld);
          }
        });
        break;
      }
      case ROLE: {
        runusers.sort((a, b) => {
          if (a.role_name === b.role_name) {
            return 0;
          } else if (
            typeof a.role_name === 'undefined' ||
            a.role_name === null
          ) {
            return -1;
          } else if (
            typeof b.role_name === 'undefined' ||
            b.role_name === null
          ) {
            return 1;
          } else {
            return a.role_name.localeCompare(b.role_name);
          }
        });
        break;
      }
      case ADDED: {
        runusers.sort((a, b) => b.created.localeCompare(a.created)); // recents first
        break;
      }
      default:
    }

    const lastNameHeader = this.columnHeader(LAST_NAME);
    const firstNameHeader = this.columnHeader(FIRST_NAME);
    const emailHeader = this.columnHeader(EMAIL);
    const accessHeader = this.columnHeader(ACCESS);
    const worldHeader = this.columnHeader(WORLD);
    const roleHeader = this.columnHeader(ROLE);
    const addedHeader = this.columnHeader(ADDED);

    return (
      <div>
        <Table striped>
          <thead className="table-wharton-pacific-blue">
            <tr>
              <th width="">{lastNameHeader}</th>
              <th width="">{firstNameHeader}</th>
              <th width="">{emailHeader}</th>
              <th width="">{accessHeader}</th>
              <th width="">{worldHeader}</th>
              <th width="">{roleHeader}</th>
              <th width="">{addedHeader} </th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {runusers.map(r => (
              <RunUserRow
                key={r.id}
                runuser={r}
                worlds={worlds}
                lti={lti}
                gameSlug={gameSlug}
                runName={runName}
                afterDelete={afterChange}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default RunUserTable;
