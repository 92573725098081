import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Container,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import { getSimplAdminUrl } from '../../utils/env';

import { getGameData } from '../../utils/gamesData';

import { getNextPhase } from '../../utils/phase';

import * as actions from '../../store/actions/wamp';

class AdvancePhaseBtn extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('AdvancePhaseBtn: openModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('AdvancePhaseBtn: closeModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  handleSubmit() {
    const {
      nextPhase,
      simplAdminToken,
      advancePhase,
      afterChange,
    } = this.props;
    advancePhase(nextPhase.name, simplAdminToken);
    afterChange();
    this.closeModal();
  }

  renderModal() {
    const { nextPhase } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Modal size="sm" isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Advance Phase</span>
        </ModalHeader>
        <ModalBody className="text-center">
          <Container>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col className="text-center">
                <p>Advance to {nextPhase.name} phase ?</p>

                <Button
                  className="btn btn-labeled btn-bay-blue float-right"
                  onClick={this.handleSubmit}
                >
                  <span className="btn-label">
                    <FontAwesomeIcon icon="play" />
                  </span>{' '}
                  Start Task
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <span>
                  <strong>NOTE</strong> tasks may take a few minutes to
                  complete.
                </span>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <Button className="btn btn-bay-blue" onClick={this.openModal}>
          Advance Phase
        </Button>
        {this.renderModal()}
      </div>
    );
  }
}

AdvancePhaseBtn.propTypes = {
  nextPhase: PropTypes.object.isRequired,
  simplAdminToken: PropTypes.string.isRequired,

  advancePhase: PropTypes.func.isRequired,
  afterChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { run } = props;
  const { gameSlug } = props.match.params;

  const gameData = getGameData(gameSlug);
  // console.log('AdvancePhaseBtn: gameSlug:', gameSlug, ', gamesData:', gamesData);

  const { simplAdminToken } = state.auth;

  const currentPhase = gameData.phases[run.phase];
  // console.log('currentPhase:', currentPhase);

  const nextPhase = getNextPhase(gameData.phases, currentPhase);
  // console.log('nextPhase:', nextPhase);

  return {
    nextPhase,
    simplAdminToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    advancePhase: (phaseName, simplAdminToken) => {
      const { run, gameSlug } = ownProps;
      // console.log('advancePhase: gameSlug:', gameSlug);
      dispatch(actions.changeRunPhase(gameSlug, run.name, phaseName))
        .then(result => {
          const { payload } = result;
          const { celery_task_id, error } = payload;
          console.log(
            'changeRunPhase celery_task_id:',
            celery_task_id,
            ', error:',
            error
          );
          if (typeof error === 'undefined') {
            // add task to database
            axios
              .post(
                `${getSimplAdminUrl()}/api/tasks/`,
                {
                  game_slug: gameSlug,
                  task_name: 'change_run_phase',
                  celery_task_id: celery_task_id,
                  run_id: run.id,
                },
                { headers: { Authorization: simplAdminToken } }
              )
              .then(res => {
                // console.log(res);
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(error => {
          console.log('Advance phase error:', error);
        });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdvancePhaseBtn)
);
