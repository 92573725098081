import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import axios from 'axios';
import { getSimplUrl } from '../../utils/env';

import EditRunNameForm from '../../containers/EditRunNameForm';

class EditNameBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otherRunNames: [],
      isModalOpen: false,
    };
  }

  componentDidMount = () => {
    const { run, gameSlug, simplToken } = this.props;

    const simplUrl = getSimplUrl();
    const url = `${simplUrl}/apis/runs/?game_slug=${gameSlug}`;
    console.log('url:', url);
    axios
      .get(url, {
        headers: { Authorization: simplToken },
      })
      .then(res => {
        const runs = res.data;
        // console.log('runs:', runs);
        const names = [];
        let r;
        for (r of runs) {
          if (r.id !== run.id) {
            names.push(r.name);
          }
        }
        // console.log('otherRunNames:', names);
        this.setState({
          otherRunNames: names,
        });
      });
  };

  openModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('EditNameBtn: openModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('EditNameBtn: closeModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  handleAfterSubmit = () => {
    const { afterChange } = this.props;
    this.closeModal();
    afterChange();
  };

  renderModal() {
    const { run } = this.props;
    const { isModalOpen, otherRunNames } = this.state;

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Edit Run Name</span>
        </ModalHeader>
        <ModalBody className="text-center">
          <EditRunNameForm
            run={run}
            otherRunNames={otherRunNames}
            afterSubmit={this.handleAfterSubmit}
          />
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Button
          className="btn btn-icon-labelled btn-bay-blue"
          onClick={this.openModal}
        >
          <span className="btn-icon">
            <FontAwesomeIcon icon="pen" />
          </span>
        </Button>

        {this.renderModal()}
      </React.Fragment>
    );
  }
}

EditNameBtn.propTypes = {
  run: PropTypes.object.isRequired,
  gameSlug: PropTypes.string.isRequired,
  simplToken: PropTypes.string.isRequired,
  afterChange: PropTypes.func.isRequired,
};

export default EditNameBtn;
