import { handleActions } from 'redux-actions';
import recycleState from 'redux-recycle';

import { recyleStateAction } from '../actions/state';

import { setConnectionStatus } from '../actions/wamp';

import { CONNECTION_STATUS } from '../../simpl-wamp/constants';

const initial = { connectionStatus: CONNECTION_STATUS.CONNECTING };

const wamp = handleActions(
  {
    [setConnectionStatus](state, action) {
      const { status } = action.payload;
      return Object.assign({}, state, { status });
    },
  },
  initial
);

export default recycleState(wamp, `${recyleStateAction}`);
