import React from 'react';
import { Table } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TaskRow from './TaskRow';

const CREATED = 1;
const NAME = 2;
const ARGS = 3;
const STATUS = 4;

class TaskTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: CREATED,
    };
  }

  selectSortByCreated = () => {
    this.setState({ sortBy: CREATED });
  };
  selectSortByName = () => {
    this.setState({ sortBy: NAME });
  };
  selectSortByArgs = () => {
    this.setState({ sortBy: ARGS });
  };
  selectSortByStatus = () => {
    this.setState({ sortBy: STATUS });
  };

  columnText = code => {
    switch (code) {
      case CREATED:
        return 'Created';
      case NAME:
        return 'Name';
      case ARGS:
        return 'Args';
      case STATUS:
        return 'Status';
      default:
        return '';
    }
  };

  columnHeader = code => {
    const { sortBy } = this.state;
    const selected = code === sortBy;
    let text = this.columnText(code);

    let header = '';
    if (selected) {
      const icon = code !== CREATED ? 'chevron-down' : 'chevron-up';
      header = (
        <span>
          <FontAwesomeIcon icon={icon} /> {text}
        </span>
      );
    } else {
      switch (code) {
        case CREATED: {
          header = (
            <span role="button" onClick={this.selectSortByCreated}>
              {text}
            </span>
          );
          break;
        }
        case NAME: {
          header = (
            <span role="button" onClick={this.selectSortByName}>
              {text}
            </span>
          );
          break;
        }
        case ARGS: {
          header = (
            <span role="button" onClick={this.selectSortByArgs}>
              {text}
            </span>
          );
          break;
        }
        case STATUS: {
          header = (
            <span role="button" onClick={this.selectSortByStatus}>
              {text}
            </span>
          );
          break;
        }
        default:
          header = '';
      }
    }
    return header;
  };

  render() {
    const { tasks, gameSlug, runId, runName } = this.props;
    // console.log('TaskTable: tasks: ', tasks);

    if (tasks.length === 0) {
      return <div />;
    }

    const { sortBy } = this.state;
    switch (sortBy) {
      case CREATED: {
        tasks.sort((a, b) => b.id - a.id); // recents first
        break;
      }
      case NAME: {
        tasks.sort((a, b) => a.task_name.localeCompare(b.task_name));
        break;
      }
      case ARGS: {
        tasks.sort((a, b) => {
          if (a.task_result === null && b.task_result == null) {
            return 0;
          } else if (a.task_result !== null && b.task_result === null) {
            return 1;
          } else if (a.task_result === null && b.task_result !== null) {
            return -1;
          } else {
            return a.task_result.task_args.localeCompare(
              b.task_result.task_args
            );
          }
        });
        break;
      }
      case STATUS: {
        tasks.sort((a, b) => {
          if (a.task_result === null && b.task_result == null) {
            return 0;
          } else if (a.task_result !== null && b.task_result === null) {
            return 1;
          } else if (a.task_result === null && b.task_result !== null) {
            return -1;
          } else {
            return a.task_result.status.localeCompare(b.task_result.status);
          }
        });
        break;
      }
      default:
    }

    const createdHeader = this.columnHeader(CREATED);
    const nameHeader = this.columnHeader(NAME);
    const argsHeader = this.columnHeader(ARGS);
    const statusHeader = this.columnHeader(STATUS);

    return (
      <div>
        <Table striped>
          <thead className="table-wharton-pacific-blue">
            <tr>
              <th width="">{createdHeader}</th>
              <th width="">{nameHeader}</th>
              <th width="">{argsHeader}</th>
              <th width="">{statusHeader}</th>
            </tr>
          </thead>

          <tbody>
            {tasks.map(t => (
              <TaskRow
                key={t.id}
                task={t}
                gameSlug={gameSlug}
                runId={runId}
                runName={runName}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default TaskTable;
