import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import CanvasSyncForm from '../../containers/CanvasSyncForm';

class CanvasSyncBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('CanvasSyncBtn: openModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    // const { isModalOpen } = this.state;
    // console.log('CanvasSyncBtn: closeModal: isModalOpen=', isModalOpen);
    this.setState({ isModalOpen: false });
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen,
    }));
  };

  handleAfterSubmit = () => {
    const { afterChange } = this.props;
    this.closeModal();
    afterChange();
  };

  renderModal() {
    const { gameSlug } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          <span>Synch Canvas Course Runs</span>
        </ModalHeader>
        <ModalBody className="text-center">
          {/*<span>CanvasSyncForm goes here.</span>*/}
          <CanvasSyncForm
            gameSlug={gameSlug}
            afterSubmit={this.handleAfterSubmit}
          />
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <Button className="btn btn-bay-blue" onClick={this.openModal}>
          Sync Canvas Course Runs
        </Button>
        {this.renderModal()}
      </div>
    );
  }
}

CanvasSyncBtn.propTypes = {
  gameSlug: PropTypes.string.isRequired,
  afterChange: PropTypes.func.isRequired,
};

export default CanvasSyncBtn;
