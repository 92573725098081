import React from 'react';
import PropTypes from 'prop-types';

import { taskRunners } from '../../constants';

import { getNYDisplayDate } from '../../utils/datetime';

import DeleteRunUserBtn from './DeleteRunUserBtn';

class RunUserRow extends React.Component {
  render() {
    const { runuser, worlds, gameSlug, runName, afterDelete } = this.props;
    // console.log('RunUserRow: runuser:', runuser);

    const {
      first_name,
      last_name,
      email,
      leader,
      role_name,
      created,
    } = runuser;
    const access = leader ? 'Leader' : 'Player';

    let world_name = '';
    if (!leader && worlds.length > 0) {
      worlds.forEach(w => {
        if (runuser.world === w.id) {
          world_name = w.name;
        }
      });
    }

    const created_date = getNYDisplayDate(created);

    let deleteBtn = '';
    if (taskRunners.has(gameSlug)) {
      deleteBtn = (
        <DeleteRunUserBtn
          runuser={runuser}
          gameSlug={gameSlug}
          runName={runName}
          afterDelete={afterDelete}
        />
      );
    }

    return (
      <tr>
        <td>{last_name}</td>
        <td>{first_name}</td>
        <td>{email}</td>
        <td>{access}</td>
        <td>{world_name}</td>
        <td>{leader ? '' : role_name}</td>
        <td>{created_date}</td>
        <td>{deleteBtn}</td>
      </tr>
    );
  }
}

RunUserRow.propTypes = {
  runuser: PropTypes.object.isRequired,
  worlds: PropTypes.array.isRequired,
  gameSlug: PropTypes.string.isRequired,
  afterDelete: PropTypes.func.isRequired,
};

export default RunUserRow;
